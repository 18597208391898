import React, { useState, useEffect } from 'react';
import {PageLayout} from '../components/layout/PageLayout/PageLayout';
import Card from '../components/common/elements/Card/Card';
import Table from '../components/common/elements/Table/Table';
import FormFilter from '../components/common/elements/FormFilter/FormFilter';
import Modal from '../components/common/elements/Modal/Modal';
import TextField from '@mui/material/TextField';
import { fetchData, postData } from '../services/api';
import Autocomplete from '@mui/material/Autocomplete';
import SnackbarAlert from '../components/common/elements/SnackbarAlert/SnackbarAlert';


const Branch = () => {
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [filterValue, setFilterValue] = useState(''); 
    const [branchName, setBranchName] = useState('');
    const [branchNameError, setBranchNameError] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [entityNames, setEntityNames] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [branchStreet, setBranchStreet] = useState('');
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [fullTableData, setFullTableData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // or "error", "info", "warning"
    
    const tableHeadData = ['Entity Name', 'Branch Name', 'Address', 'Code', 'Status', 'Action'];
 
    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };
        
    const handleStatusChange = async ({ newStatus, rowIndex, row }) => {
        const originalData = [...tableData]; 
        const updatedData = [...tableData];
        updatedData[rowIndex].status = newStatus;
      
        const payload = { 
          BranchName: row.name, 
          ReferenceTableStatusID: newStatus === 'Active' ? 1 : 2 
        };
      
        try {
          await postData('branches/update', { ...payload, BranchID: row.id }); 
          setTableData(updatedData);
        } catch (error) {
          console.error("Failed to update:", error);
          setTableData(originalData);
        }
    }; 

    const handleButtonClick = (rowId) => {
      const row = fullTableData.find((data) => data.id === rowId);      

      if (row) {
          setSelectedRowData(row);
          setBranchName(row.name);

          setSelectedEntity(entityNames.find((entity) => entity.EntityNameID === row.EntityNameID));
          setSelectedAddress(addresses.find((address) => address.BarangayID === row.BarangayID));
          setBranchStreet(row.Street);
          setOpenModal(true);
      }
    };
  
    const retrieveEntityInfo = async () => {
        await fetchData('entitynames/info')
        .then((result) => {
            setEntityNames(result['entitynames']);
            setAddresses(result['address']);
        })
        .catch((error) => console.error('Error fetching data:', error));        
    };

    const performSearch = async () => {
        await fetchData('branches', { filter: filterValue })
        .then((result) => {
            if (result.code === 404) {
              setTableData([]); 
              setSnackbarMessage(result.message);
              setSnackbarOpen(true);              
            } else {
                const resultArray = Object.values(result.data).map((item) => ({
                  id: item.BranchID,
                  entityname: item.EntityName,
                  name: item.BranchName,
                  address: item.Address,
                  code: item.BranchCode,
                  status: item.TableStatus,
                  EntityNameID: item.EntityNameID,
                  BarangayID: item.BarangayID,
                  Street: item.Street,
                }));

                setFullTableData(resultArray);
                setTableData(resultArray.map(({ EntityNameID, BarangayID, Street, ...rest }) => rest));                

            }          
          })
          .catch((error) => console.error('Error fetching data:', error));
    };
    
    const performNew = () => {
        setBranchName('');
        setBranchStreet('');
        setBranchNameError(false);
        setSelectedRowData(null); 
        setSelectedEntity(null);
        setSelectedAddress(null);        
        setOpenModal(true);
    }; 

    const performClear = () => {
        setTableData([]); 
        setFilterValue(''); 
    };    

    const handleSaveModal = async () => {
        setBranchNameError(!branchName);
      
        if (!branchName) return;
      
        try {
          const payload = { 
            BranchName: branchName,
            EntityNameID: selectedEntity?.EntityNameID,
            BarangayID: selectedAddress?.BarangayID,
            Street: branchStreet
          };          
          const endpoint = selectedRowData ? 'branches/update' : 'branches';
      
          const { data } = await postData(endpoint, {
            ...payload,
            BranchID: selectedRowData?.id
          });
          
          if (data.length > 0) {
            const { BranchID: id, BranchCode: code, BranchName: name, EntityName: entityname, Address: address, TableStatus: status } = data[0];
            const newEntry = { id, entityname,name, address, code, status };
      
            setFullTableData((prevFullTableData) => {
              if (selectedRowData) {
                return prevFullTableData.map((item) => (item.id === selectedRowData.id ? { ...newEntry, EntityNameID: selectedEntity?.EntityNameID, BarangayID: selectedAddress?.BarangayID, Street: branchStreet } : item));
              } else {
                return [...prevFullTableData, { ...newEntry, EntityNameID: selectedEntity?.EntityNameID, BarangayID: selectedAddress?.BarangayID, Street: branchStreet }];
              }
            });

            setTableData([]); 
            setTableData((prevTableData) => {
              if (selectedRowData) {
                return prevTableData.map((item) => (item.id === selectedRowData.id ? newEntry : item));
              } else {
                return [...prevTableData, newEntry];
              }
            });
      
            setOpenModal(false);
            
            const action = selectedRowData ? 'updated' : 'added';
            setSnackbarMessage(`Record successfully ${action}.`);
            setSnackbarSeverity('success');            
            setSnackbarOpen(true);      
          }
        } catch (error) {
          console.error('Error saving:', error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };    

    useEffect(() => {
        retrieveEntityInfo();
    }, []); 

    return (
        <PageLayout
        breadcrumbs={[
            { label: "Home", href: "/" },
            { label: "Setup", href: "#" },
            { label: "Branch", href: "#" },
        ]}
        >
            <Card>
                <FormFilter 
                  handleSearch={performSearch} 
                  handleNew={performNew} 
                  handleClear={performClear} 
                  filterValue={filterValue} 
                  setFilterValue={setFilterValue}            
                />
            </Card>
            <div style={{ marginBottom: '20px' }}></div>
            <Table tableHeadData={tableHeadData} tableData={tableData} handleButtonClick={handleButtonClick} onStatusChange={handleStatusChange} />        
            <Modal 
                width={400} 
                open={openModal} 
                handleSave={handleSaveModal} 
                handleClose={handleCloseModal} 
                modalTitle="Branch" 
            >
                <Autocomplete
                    disablePortal
                    options={entityNames}
                    getOptionLabel={(option) => option.EntityName}
                    onChange={(event, newValue) => { setSelectedEntity(newValue); }}
                    value={selectedEntity} 
                    renderOption={(props, option) => <li {...props} key={option.EntityNameID}>{option.EntityName}</li>}
                    sx={{ width: '100%', mb: 2 }}
                    renderInput={(params) => <TextField {...params} label="Entity Name" />}
                />

                <TextField
                    fullWidth
                    label="Branch Name"
                    type="search"
                    placeholder="Enter name here"
                    sx={{ mb: 2 }}
                    required
                    error={branchNameError}
                    helperText={branchNameError ? 'BranchName is required' : ''}
                    value={branchName}
                    onChange={e => setBranchName(e.target.value)}                    
                /> 

                <Autocomplete
                    disablePortal
                    options={addresses}
                    getOptionLabel={(option) => option.Address}
                    onChange={(event, newValue) => { setSelectedAddress(newValue); }}
                    value={selectedAddress} 
                    renderOption={(props, option) => <li {...props} key={option.BarangayID}>{option.Address}</li>}
                    sx={{ width: '100%', mb: 2 }}
                    renderInput={(params) => <TextField {...params} label="Address" />}
                />

                <TextField
                    fullWidth
                    label="Street"
                    type="text"
                    multiline
                    minRows={3}
                    placeholder="Enter address here"
                    sx={{ mb: 2 }}
                    value={branchStreet}
                    onChange={e => setBranchStreet(e.target.value)}                     
                />

            </Modal>   

            <SnackbarAlert 
              open={snackbarOpen} 
              handleClose={handleSnackbarClose} 
              message={snackbarMessage} 
              severity={snackbarSeverity}
            />
        </PageLayout>
    );
};

export default Branch;