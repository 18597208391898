import React, { useState, useEffect } from 'react';
import {PageLayout} from '../components/layout/PageLayout/PageLayout';

const Profile = () => {

  return (
    <PageLayout
      breadcrumbs={[
        { label: "Home", href: "/" },
        { label: "Profile", href: "#" },
      ]}
    >
      <>
        <h2 style={{color: 'white'}}>Welcome to the Profile</h2>

      </>
    </PageLayout>
  );
};

export default Profile;
