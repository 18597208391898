import React, { useState, useEffect } from 'react';
import classes from './TabMenu.module.css';
import FilterTourActivities from './FilterTourActivities';
import FilterHotel from './FilterHotels';
import FilterFlights from './FilterFlights';
import { useLocation } from 'react-router-dom';
import { TravelLogo, HotelLogo, PlaneLogo } from '../../../helpers/MenuLogo';

const TabMenu = () => {
    const [activeTab, setActiveTab] = useState('tours');
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get('tm');
        if (tab === 'tours' || tab === 'hotels' || tab === 'flights') {
            setActiveTab(tab);
        }
    }, [location]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const isActive = (tab) => {
        return activeTab === tab ? classes.active : '';
    };

    return (
        <div className={classes.container}>
            <div className={classes['tab-menu']}>
                <div 
                  className={`${classes['menu-tour-activities']} ${isActive('tours')}`}
                  onClick={() => handleTabClick('tours')}
                  tabIndex={0}
                  aria-label="Tours and Activities"
                  onKeyDown={(e) => e.key === 'Enter' && handleTabClick('tours')}
                >
                    <div className={classes['icon-wrapper']}>
                        <TravelLogo className={classes.menuLogo} />
                    </div>    
                    <a className={classes['menu-tour-text']} href="#">Tours & Activities</a>
                </div>
                <div 
                  className={`${classes['menu-hotel']} ${isActive('hotels')}`}
                  onClick={() => handleTabClick('hotels')}
                  tabIndex={0}
                  aria-label="Hotels"
                  onKeyDown={(e) => e.key === 'Enter' && handleTabClick('hotels')}
                >
                    <div className={classes['icon-wrapper']}>
                        <HotelLogo className={classes.menuLogo} />
                    </div>    
                    <a className={classes['menu-tour-text']} href="#">Hotels</a>
                </div>
                <div 
                  className={`${classes['menu-flights']} ${isActive('flights')}`}
                  onClick={() => handleTabClick('flights')}
                  tabIndex={0}
                  aria-label="Flights"
                  onKeyDown={(e) => e.key === 'Enter' && handleTabClick('flights')}
                >
                    <div className={classes['icon-wrapper']}>
                        <PlaneLogo className={classes.menuLogo} />
                    </div>    
                    <a className={classes['menu-tour-text']} href="#">Flights</a>
                </div>
            </div>
            {activeTab === 'tours' && <FilterTourActivities />}
            {activeTab === 'hotels' && <FilterHotel />}
            {activeTab === 'flights' && <FilterFlights />}            

            
        </div>
    );
};

export default TabMenu;
