import React, { useState, useEffect } from 'react';
import {PageLayout} from '../../components/layout/PageLayout/PageLayout';

const Membership = () => {

  return (
        <h2>Welcome to the Membership!</h2>
  );
};

export default Membership;
