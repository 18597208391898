import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveMenus, getToken } from '../services/storage/indexedDB';
import { LoginScreen } from './LoginScreen';
import { fetchMenuData } from '../helpers/fetchMenuData';

const Login = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLoginScreen, setShowLoginScreen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getToken();

      setIsAuthenticated(!!token);
      setShowLoginScreen(!token);
    };

    fetchToken();
  }, [isAuthenticated]);

  const handleLoginSubmit = async (isAuth) => {
    setIsAuthenticated(isAuth);
    setShowLoginScreen(!isAuth);
    if (isAuth) {
      const menuData = await fetchMenuData();
      await saveMenus(menuData); 
      navigate('/dashboard');
    }
  };

  if (!isAuthenticated) {
    return showLoginScreen && <LoginScreen onSubmit={handleLoginSubmit} />;
  }

};

export default Login;



