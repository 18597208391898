import React, { useState, useEffect } from 'react';
import {PageLayout} from '../components/layout/PageLayout/PageLayout';
import Card from '../components/common/elements/Card/Card';
import Table from '../components/common/elements/Table/Table';
import FormFilter from '../components/common/elements/FormFilter/FormFilter';
import Modal from '../components/common/elements/Modal/Modal';
import TextField from '@mui/material/TextField';
import { fetchData, postData } from '../services/api';
import SnackbarAlert from '../components/common/elements/SnackbarAlert/SnackbarAlert';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import {GROUP_USER_OPTIONS} from '../utils/moduleConstants';


const Permission = () => {
  const [modalTitle, setModalTitle] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableDataModules, setTableDataModules] = useState([]);
  const [fullTableData, setFullTableData] = useState([]);    
  const [filterValue, setFilterValue] = useState('');   
  const [usersOption, setUsersOption] = useState([]);
  const [groupsOption, setGroupsOption] = useState([]);
  const [branchesOption, setBranchesOption] = useState([]);
  const [systemsOption, setSystemsOption] = useState([]);
  const [systemsOptionModal, setSystemsOptionModal] = useState([]);
  const [systemsModuleOption, setSystemsModuleOption] = useState([]);
  const [isMultipleSelectionAllowed, setIsMultipleSelectionAllowed] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // or "error", "info", "warning"  
  
  const [selectedUserOptions, setSelectedUserOptions] = useState(null);
  const [selectedUserOptionsModal, setSelectedUserOptionsModal] = useState(null);
  const [selectedGroupOptions, setSelectedGroupOptions] = useState(null);
  const [selectedGroupOptionsModal, setSelectedGroupOptionsModal] = useState(null);
  const [selectedBranchesOption, setSelectedBranchesOption] = useState(null);
  const [selectedBranchesOptionModal, setSelectedBranchesOptionModal] = useState(null);
  const [selectedSystemsOption, setSelectedSystemsOption] = useState(null);
  const [selectedSystemsOptionModal, setSelectedSystemsOptionModal] = useState(null);
  const [selectedSystemsModuleOption, setSelectedSystemsModuleOption] = useState(null);
  const [selectedGroupUserFilter, setSelectedGroupUserFilter] = useState(GROUP_USER_OPTIONS[0]);
  const [selectedGroupUserModal, setSelectedGroupUserModal] = useState(GROUP_USER_OPTIONS[0]);
  const [selectedSystemError, setSelectedSystemError] = useState(false);
  const [selectedBranchError, setSelectedBranchError] = useState(false);
  const [selectedModuleError, setSelectedModuleError] = useState(false);
  const [selectedGroupModalError, setSelectedGroupModalError] = useState(false);
  const [selectedUserloginModalError, setSelectedUserloginModalError] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showTable, setShowTable] = useState(true); 
    
  const tableHeadData = ['Branch', 'System', 'Module', 'Controller', 'Group/User', 'Status', 'Action'];
  const tableHeadDataModules = ['List of Modules'];
  const autocompletes = [
    {
      options: GROUP_USER_OPTIONS,
      getOptionLabel: (option) => option.label,
      renderInput: (params) => <TextField {...params} label="Group/User Filter" />,
      defaultValue: selectedGroupUserFilter,
      customProps: { autoCompleteWidth: '12%' },
      onChange: (event, newValue) => {
        if (newValue) {
          if (newValue.value === 'group') {
            setSelectedUserOptions(null);
          }          
          setSelectedGroupUserFilter(newValue);
        }
      },
    },
    ...(selectedGroupUserFilter && selectedGroupUserFilter.value === 'group' 
      ? [{
          options: groupsOption,
          getOptionLabel: (option) => option.UserGroupName || '',
          renderInput: (params) => <TextField {...params} label="Choose Group" />,
          defaultValue: selectedGroupOptions,
          customProps: { autoCompleteWidth: '12%' },
          renderOption: (props, option) => <li {...props} key={option.UserGroupID}>{option.UserGroupName}</li>,
          onChange: (event, newValue) => {
            if (newValue) {
              setSelectedGroupOptions(newValue);
            } else {
              setSelectedGroupOptions(null);
            }
          },
        }]
      : [{
          options: usersOption,
          getOptionLabel: (option) => option.UserLoginName || '',
          renderInput: (params) => <TextField {...params} label="Choose User Login" />,
          defaultValue: selectedUserOptions,
          customProps: { autoCompleteWidth: '12%' },
          renderOption: (props, option) => <li {...props} key={option.UserLoginID}>{option.UserLoginName}</li>,
          onChange: (event, newValue) => {
            if (newValue) {
              setSelectedUserOptions(newValue);
            } else {
              setSelectedUserOptions(null);
            }
          },
        }]
    ),    
    {
      options: branchesOption,
      getOptionLabel: (option) =>  option.BranchName || '',
      renderInput: (params) => <TextField {...params} label="Choose Branch" />,
      defaultValue: selectedBranchesOption,
      customProps: { autoCompleteWidth: '15%' },
      renderOption: (props, option) => <li {...props} key={option.BranchID}>{option.EntityName + ' > ' + option.BranchName}</li>,
      onChange: (event, newValue) => {
        setSelectedBranchesOption(newValue);
      },
    },
    {
      options: systemsOption,
      getOptionLabel: (option) => option.SystemName || '',
      renderInput: (params) => <TextField {...params} label="Choose System" />,
      defaultValue: selectedSystemsOption,
      customProps: { autoCompleteWidth: '20%' },
      onChange: (event, newValue) => {
        setSelectedSystemsOption(newValue);
      },
    },    
  ];

  const handleCheckboxChange = (index, checked) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index] = checked;
    setCheckboxes(newCheckboxes);
    // console.log("Updated Checkboxes:", newCheckboxes); 
  };
  
  const handleSelectAllChange = () => {
    const areAllChecked = checkboxes.every(val => val);
    const newCheckboxes = checkboxes.map(() => !areAllChecked);
    setCheckboxes(newCheckboxes);
  };
  



  const performSearch = async () => {
    // Validation checks at the start
    if (selectedGroupUserFilter.value === 'user' && !selectedUserOptions) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('You must select a user');
      setSnackbarOpen(true);  

      return false;
    }    

    let filter_data = {
      'GroupUserOption' : selectedGroupUserFilter ? selectedGroupUserFilter.value : null,
      'GroupID' : selectedGroupOptions ? selectedGroupOptions.UserGroupID : null,
      'UserLoginID' : selectedUserOptions ? selectedUserOptions.UserLoginID : null,
      'BranchID' : selectedBranchesOption ? selectedBranchesOption.BranchID : null,
      'SystemID' : selectedSystemsOption ? selectedSystemsOption.SystemID : null,
      'SearchValue' : filterValue
    }

    await fetchData('permissions', filter_data)
    .then((result) => {
        if (result.code === 404) {
          setTableData([]); 
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);              
        } else {
          const resultArray = Object.values(result.data).map((item) => {
            return {
              id: item.UserRightID,
              branch: item.BranchName,
              system: item.SystemName,
              module: item.ModuleName,
              controller: item.ModuleController,
              group_user: selectedGroupUserFilter.value === 'user' ? item.UserLoginName : item.UserGroupName,
              status: item.StatusName,
              SystemID: item.SystemID,
              BranchID: item.BranchID,
              UserGroupID: item.UserGroupID,
              ModuleID: item.ModuleID,
              UserLoginID: item.UserLoginID,
            };
          });           

          setFullTableData(resultArray);
          setTableData(resultArray.map(({ SystemID, BranchID, UserGroupID,ModuleID,UserLoginID, ...rest }) => rest));                
        }          
      })
      .catch((error) => console.error('Error fetching data:', error));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  }; 
  const performNew = () => {
    setModalTitle('New Permission'); 
    setSelectedSystemsOptionModal(null);
    setSelectedBranchesOptionModal(null);
    setSelectedGroupOptionsModal(null);
    setSelectedUserOptionsModal(null);
    setSelectedGroupUserModal(GROUP_USER_OPTIONS[0]);
    setTableDataModules([]);
    
    setIsMultipleSelectionAllowed(true);
    setOpenModal(true);
    setShowTable(true);
  };
  
  const handleButtonClick = (rowId) => {
    setIsMultipleSelectionAllowed(false);
    const row = fullTableData.find((data) => data.id === rowId);      
    console.log(row)
    if (row) {
      setModalTitle('Update Permission');
      setSelectedRowData(row);
      setOpenModal(true);
      setShowTable(false);
    }

  }

  const handleStatusChange = async ({ newStatus, rowIndex, row }) => {
    const originalData = [...tableData]; 
    const updatedData = [...tableData];
    updatedData[rowIndex].status = newStatus;
  
    const payload = { 
      ReferenceTableStatusID: newStatus === 'Active' ? 1 : 2 
    };

    try {
      await postData('permissions/update/status', { ...payload, UserRightID: row.id }); 
      setTableData(updatedData);
    } catch (error) {
      console.error("Failed to update:", error);
      setTableData(originalData);
    }
  }; 


  const handleSaveModal = async () => {

    setSelectedSystemError(!selectedSystemsOptionModal);
    if (!selectedSystemsOptionModal) return;    

    setSelectedBranchError(!selectedBranchesOptionModal);
    if (!selectedBranchesOptionModal) return;    

    if (selectedGroupUserModal.value === 'group') {
      setSelectedGroupModalError(!selectedGroupOptionsModal);
      if (!selectedGroupOptionsModal) return;
    }
  
    if (selectedGroupUserModal.value === 'user') {
      setSelectedUserloginModalError(!selectedUserOptionsModal);
      if (!selectedUserOptionsModal) return;
    }




    const systemID = selectedSystemsOptionModal.SystemID;
    const branchID = selectedBranchesOptionModal.BranchID;
    let userGroupID = selectedGroupOptionsModal?.UserGroupID  || "";
    let userLoginID = selectedUserOptionsModal?.UserLoginID || "";

    const selectedValues = tableDataModules.filter((_, index) => checkboxes[index]);
    const ids = selectedValues.map(item => item.id);

    if (showTable) {
  
      if (ids.length === 0) {
        setSnackbarSeverity('warning');
        setSnackbarMessage('Please select at least 1 module');
        setSnackbarOpen(true);       
        return;
      }
  
    }else{
      if (selectedSystemsModuleOption == null) {
          setSnackbarSeverity('warning');
          setSnackbarMessage('Please select a module');
          setSnackbarOpen(true);       
          return;
      }
    }

    if (selectedGroupUserModal.value === 'group') {
      userLoginID = null;
    }else{
      userGroupID = null;
    }

    try {

      const details = ids.map(id => ({
        SystemID: systemID.toString(),
        BranchID: branchID.toString(),
        UserGroupID: userGroupID ? userGroupID.toString() : "",
        UserLoginID: userLoginID ? userLoginID.toString() : "",
        ModuleID: id.toString()
      }));
    
      const payload = { Details: details };          

      const endpoint = showTable ? 'users/permission' : 'users/permission/update';
      console.log(selectedRowData); return;
      const { data } = await postData(endpoint, {
        ...payload,
        UserRightID: selectedRowData?.id
      });

      setOpenModal(false);

    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage(error);
      setSnackbarOpen(true);        
    }    


  }
  

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const performClear = () => {
    setSelectedUserOptions(null);        
    setSelectedBranchesOption(null);
    setSelectedSystemsOption(null);

    setTableData([]); 
    setFilterValue(''); 
  };  

  const retrieveSystemModule = async (param = {}) => {
    await fetchData('modules', {SystemID: param.SystemID})
    .then((result) => {
      
      if (result.code === 404) {
        setSnackbarMessage(result.message);
        setSnackbarOpen(true); 
      }else{
        const resultArray = Object.values(result.data).map((item) => {
          const ModulePath = [item.ParentModule, item.ChildModule, item.ModuleName]
          .filter(Boolean)
          .join(' > ');

          return {
            id: item.ModuleID,
            ModuleID: item.ModuleID,
            ModulePath: ModulePath
          };
        });         

        setSystemsModuleOption(resultArray);   
        setTableDataModules(resultArray.map(({ ModuleID, ...rest }) => rest));                        
      }      

    })
    .catch((error) => console.error('Error fetching data:', error));     
  }

  const retrievePermissionFilter = async () => {
    await fetchData('permissions/filter')
    .then((result) => {
        setUsersOption(result['logins'] || []);
        setGroupsOption(result['groups'] || []);
        setBranchesOption(result['branches'] || []);
        setSystemsOption(result['systems'] || []);
        setSystemsOptionModal(result['systems'] || []);
    })
    .catch((error) => console.error('Error fetching data:', error));        
  };

  useEffect(() => {
    retrievePermissionFilter();
  }, []); 

  useEffect(() => {
    setCheckboxes(tableDataModules.map(() => false));
  }, [tableDataModules]);  

  return (
    <PageLayout sm={3} itemLeftPadding={2} alignItems="flex-start" 
      breadcrumbs={[
        { label: "Home", href: "/" },
        { label: "Setup", href: "#" },
        { label: "Manage Permission", href: "#" }
      ]}
    >
      <Card>
        <FormFilter 
          handleSearch={performSearch} 
          handleNew={performNew} 
          handleClear={performClear} 
          filterValue={filterValue} 
          setFilterValue={setFilterValue}                      
          autocompletes={autocompletes}                     
        />
      </Card>      
      <div style={{ marginBottom: '20px' }}></div>
      <Table tableHeadData={tableHeadData} tableData={tableData} handleButtonClick={handleButtonClick} onStatusChange={handleStatusChange} />
      <Modal 
          width={800} 
          height={750}
          open={openModal} 
          handleSave={handleSaveModal} 
          handleClose={handleCloseModal} 
          modalTitle={modalTitle}
      > 
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Autocomplete 
                options={systemsOptionModal}
                getOptionLabel={(option) => option.SystemNameWithCode}
                onChange={(event, newValue) => { 
                  setSelectedSystemsOptionModal(newValue); 
                  retrieveSystemModule(newValue);
                }}
                value={selectedSystemsOptionModal}
                isOptionEqualToValue={(option, value) => option && value && option.SystemID === value.SystemID}
                renderOption={(props, option) => <li {...props} key={option.SystemID}>{option.SystemNameWithCode}</li>}
                sx={{ width: '100%', mb: 2 }}
                renderInput={(params) => 
                  <TextField 
                    {...params} 
                    label="System Name" 
                    error={selectedSystemError} 
                    helperText={selectedSystemError ? 'System Name is required' : ''} 
                  />
                }                      
              />          
            </Grid>
            <Grid item xs={6}>
              <Autocomplete 
                  options={branchesOption}
                  getOptionLabel={(option) => option.BranchName}
                  onChange={(event, newValue) => { 
                    setSelectedBranchesOptionModal(newValue); 
                  }}
                  value={selectedBranchesOptionModal}
                  isOptionEqualToValue={(option, value) => option && value && option.BranchID === value.BranchID}
                  renderOption={(props, option) => <li {...props} key={option.BranchID}>{option.EntityName + ' > ' + option.BranchName}</li>}
                  sx={{ width: '100%', mb: 2 }}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      label="Branch Name" 
                      error={selectedBranchError} 
                      helperText={selectedBranchError ? 'Branch Name is required' : ''} 
                    />
                  }                      
                />               
            </Grid>
            <Grid item xs={6}>
              <Autocomplete 
                  clearIcon={null}
                  options={GROUP_USER_OPTIONS}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => { 
                    setSelectedGroupUserModal(newValue); 
                  }}
                  value={selectedGroupUserModal}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  sx={{ width: '100%', mb: 2 }}
                  renderInput={(params) => <TextField {...params} label="Group/User Filter" />}                
              />         
            </Grid>
            <Grid item xs={6}>
                {selectedGroupUserModal.value === 'group' ? (
                    <Autocomplete 
                        clearIcon={null}
                        options={groupsOption}
                        getOptionLabel={(option) => option.UserGroupName}
                        onChange={(event, newValue) => { 
                            setSelectedGroupOptionsModal(newValue); 
                        }}
                        value={selectedGroupOptionsModal}
                        isOptionEqualToValue={(option, value) => option && value && option.UserGroupID === value.UserGroupID}
                        renderOption={(props, option) => <li {...props} key={option.UserGroupID}>{option.UserGroupName}</li>}
                        sx={{ width: '100%', mb: 2 }}
                        renderInput={(params) => 
                          <TextField 
                            {...params} 
                            label="Group" 
                            error={selectedGroupModalError} 
                            helperText={selectedGroupModalError ? 'Group is required' : ''} 
                          />
                        }                        
                    />
                ) : (
                    <Autocomplete 
                        clearIcon={null}
                        options={usersOption}
                        getOptionLabel={(option) => option.UserLoginName}
                        onChange={(event, newValue) => { 
                            setSelectedUserOptionsModal(newValue); 
                        }}
                        value={selectedUserOptionsModal}
                        isOptionEqualToValue={(option, value) => option && value && option.UserLoginID === value.UserLoginID}
                        renderOption={(props, option) => <li {...props} key={option.UserLoginID}>{option.UserLoginName}</li>}
                        sx={{ width: '100%', mb: 2 }}
                        renderInput={(params) => 
                          <TextField 
                            {...params} 
                            label="Userlogin" 
                            error={selectedUserloginModalError} 
                            helperText={selectedUserloginModalError ? 'Userlogin is required' : ''} 
                          />
                        }                        

                        // renderInput={(params) => <TextField {...params} label="Userlogin" />}                
                    />
                )}
            </Grid>                                              
            <Grid item xs={12}>
              {showTable ? ( 
                <Table 
                  showDynamicButton={false}
                  showCheckbox={true}
                  tableHeadData={tableHeadDataModules}
                  tableData={tableDataModules}  
                  checkboxes={checkboxes}
                  onCheckboxChange={handleCheckboxChange}
                  onSelectAllChange={handleSelectAllChange}                
                />
              ) : (
                <Autocomplete 
                  options={systemsModuleOption}
                  getOptionLabel={(option) => option.ModulePath}
                  onChange={(event, newValue) => { 
                    setSelectedSystemsModuleOption(newValue); 
                  }}
                  value={selectedSystemsModuleOption}
                  isOptionEqualToValue={(option, value) => option && value && option.ModuleID === value.ModuleID}
                  renderOption={(props, option) => <li {...props} key={option.ModuleID}>{option.ModulePath}</li>}
                  sx={{ width: '100%', mb: 2 }}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      label="Module Name" 
                      error={selectedBranchError} 
                      helperText={selectedBranchError ? 'Module Name is required' : ''} 
                    />
                  }                      
                />                
              )}              
              {/* <Table 
                showDynamicButton={false}
                showCheckbox={true}
                tableHeadData={tableHeadDataModules}
                tableData={tableDataModules}  
                checkboxes={checkboxes}
                onCheckboxChange={handleCheckboxChange}
                onSelectAllChange={handleSelectAllChange}                
               />          */}
            </Grid>            
          </Grid>

      </Modal>     
      <SnackbarAlert 
          open={snackbarOpen} 
          handleClose={handleSnackbarClose} 
          message={snackbarMessage} 
          severity={snackbarSeverity}
      />      
    </PageLayout>    
  );
};

export default Permission;
