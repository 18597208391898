import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import Entity from './screens/Entity';
import Group from './screens/Group';
import GroupMember from './screens/GroupMember';
import User from './screens/User';
import Permission from './screens/Permission';
import System from './screens/System';
import NotFound from './screens/NotFound';
import Profile from './screens/Profile';
import Branch from './screens/Branch';
import Module from './screens/Module';
import Booking from './screens/booking/Booking';
import ExploreDestination from './screens/booking/ExploreDestinations';
import SearchBooking from './screens/booking/search-booking/SearchBooking';
import AboutUs from './screens/footer/AboutUs';
import MeetTheTeam from './screens/footer/MeetTheTeam';
import ContactUs from './screens/footer/ContactUs';
import Membership from './screens/membership/Membership';
import TourAndActivity from './screens/setup/TourAndActivity';
import TourActivityDetail from './screens/booking/search-booking/TourActivityDetail';
import ProtectedLayout from './components/common/ProtectedLayout/ProtectedLayout'; 

const AppRoutes = ({ isSidebarOpen, toggleSidebar }) => { 
  return (
    <Routes>
      {/* Public routes (not protected) */}
      <Route path="/" element={<Booking />} />
      <Route path="/login" element={<Login />} />
      <Route path="/explore-destinations" element={<ExploreDestination />} />
      <Route path="/search-booking" element={<SearchBooking />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/meet-the-team" element={<MeetTheTeam />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/activity-detail" element={<TourActivityDetail />} />

      {/* Protected routes */}
      <Route path="/" element={<ProtectedLayout isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />}>
        {/* Added props to ProtectedLayout */}
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="membership" element={<Membership />} />
        <Route path="package/tour" element={<TourAndActivity />} />
        <Route path="entity" element={<Entity />} />
        <Route path="systems" element={<System />} />
        <Route path="user/roles" element={<User />} />
        <Route path="user/group" element={<Group />} />
        <Route path="user/group/member" element={<GroupMember />} />
        <Route path="user/permission" element={<Permission />} />
        <Route path="profile" element={<Profile />} />
        <Route path="branches" element={<Branch />} />
        <Route path="modules" element={<Module />} />
      </Route>

      {/* Fallback route for unknown paths */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
