import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MouseActivityTracker from './components/common/MouseActivityTracker/MouseActivityTracker';
import ClearOnBrowserClose from './components/LocalStorage/ClearOnBrowserClose';
import AppRoutes from './Routes';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="App">
      <Router>
        <MouseActivityTracker />
        <AppRoutes isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <ClearOnBrowserClose />
      </Router>
    </div>
  );
}

export default App;