import React, { useState } from 'react';
import {PageLayout} from '../components/layout/PageLayout/PageLayout';
import Card from '../components/common/elements/Card/Card';
import Table from '../components/common/elements/Table/Table';
import FormFilter from '../components/common/elements/FormFilter/FormFilter';
import Modal from '../components/common/elements/Modal/Modal';
import TextField from '@mui/material/TextField';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const tableData = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];


const User = () => {
  const [openModal, setOpenModal] = useState(false);

  const tableHeadData = [
    'Dessert (100g serving)',
    'Calories',
    'Fat (g)',
    'Carbs (g)',
    'Action',
  ];

  const performSearch = () => {
    // Perform your custom search logic here
    console.log('Perform custom search from user');
  };

  const performNew = () => {
    // Perform your custom search logic here
    setOpenModal(true);
    
  };
  
  const performClear = () => {
    alert('Clear Fields from User')
  };  

  const handleSaveModal = () => {
    alert('save from user')
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <PageLayout
      sm={3}
      itemLeftPadding={2}
      alignItems="flex-start"
      breadcrumbs={[
        { label: "Home", href: "/" },
        { label: "Setup", href: "#" },
        { label: "Manage Roles", href: "#" }
      ]}      
    >
      <Card>
        <FormFilter 
          handleSearch={performSearch} 
          handleNew={performNew} 
          handleClear={performClear} 
        />
      </Card>
      <div style={{ marginBottom: '20px' }}></div>
      <Table tableHeadData={tableHeadData} tableData={tableData} />
      <Modal 
        width={400} 
        open={openModal} 
        handleSave={handleSaveModal} 
        handleClose={handleCloseModal} 
        modalTitle="User" 
      >
        <TextField fullWidth label="Firstname" type="search" sx={{ mb: 2 }} />
        <TextField fullWidth label="Lastname" type="search" />
      </Modal>
    </PageLayout>    
    
  );
};

export default User;
