import React from 'react';
import classes from './MeetTheTeam.module.css';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import founder1 from '../../assets/image/team/founder1.png'; 
import founder2 from '../../assets/image/team/founder2.png'; 
import kiko from '../../assets/image/team/coach-kiko.jpg'; 
import john from '../../assets/image/team/john.jpg'; 
import edmar from '../../assets/image/team/edmar.jpg'; 
import raffy from '../../assets/image/team/raffy.jpg'; 
import genesis from '../../assets/image/team/raffy.jpg'; 

const MeetTheTeam = () => {

  const teamMembers = [
    { name: "MARK JONES N. DELA CRUZ", title: "CEO", imageUrl: founder2 },
    { name: "RAQUEL 'Aking' RUIZ", title: "COO", imageUrl: founder1 },
    { name: "FRANCIS 'Kiko' JAVIER", title: "Chief, Digital Marketing Specialist", imageUrl: kiko },
    { name: "EDMAR TORCULAS", title: "Chief Marketing Specialist", imageUrl: edmar },
    { name: "JOHN G.", title: "Software Engineer", imageUrl: john },
    // { name: "RAFFY GONZALES", title: "Chief Customer Service Specialist", imageUrl: raffy },
    // { name: "GENESIS SUARING", title: "Customer Service Specialist", imageUrl: genesis },
  ];
  
  return (
    <>
    <PublicHeader />
    <div className={classes.teamContainer}>
      <h2>Meet Our Team</h2>
      <div className={classes.teamGrid}>
        {teamMembers.map(member => (
          <div key={member.name} className={classes.teamMember}>
            <img src={member.imageUrl} alt={member.name} className={classes.teamImage} />
            <h3>{member.name}</h3>
            <p>{member.title}</p>
          </div>
        ))}
      </div>
    </div>
    <PublicFooter />
    </>
  );
};

export default MeetTheTeam;
