import React,{useState} from 'react';
import classes from './FilterHotels.module.css';
import { formatDate } from '../../../utils/dateUtils';
import ContentHotels from './ContentHotels';


const FilterHotel = () => {
    const [destination, setDestination] = useState('');
    const [fromDate, setFromDate] = useState(formatDate({ date: new Date() }));    
    const [toDate, setToDate] = useState(formatDate({ date: new Date() }));    

    const handleSearch = () => {
        console.log("Searching for:", { destination, fromDate, toDate });
    };

    return (
        <>
            <div className={classes.container}>
                <div className={classes.filterForm}>
                    <div className={classes.selectContainer}>
                        <label>Hotel Name</label>
                        <select value={destination} onChange={(e) => setDestination(e.target.value)}>
                            <option value="">Select Hotel</option>
                            <option value="place1">Crown Regency</option>
                            <option value="place2">Plantation Bay</option>
                            <option value="place2">Seda Hotel</option>
                            <option value="place2">Nustar Hotel</option>
                        </select>
                    </div>

                    <div className={classes.dateContainer}>
                        <label htmlFor="fromDate">Check-in</label>
                        <input id="fromDate" type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                    </div>

                    <div className={classes.dateContainer}>
                        <label htmlFor="toDate">Check-out</label>
                        <input id="toDate" type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                    </div>

                    <button onClick={handleSearch}>Search</button>
                </div>
            </div>
            <ContentHotels />
        </>
    );
};

export default FilterHotel;

