import React from 'react';
import { Modal, Box, Typography, Button, Grid } from '@mui/material';
import { SaveSharp, CloseSharp, Person } from '@mui/icons-material';
import classes from './Modal.module.css';

const CustomModal = (props) => {
    const { open, handleClose, handleSave, width, height, children, modalTitle = 'Please set a modal title', modalTitleRight = '' } = props;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width || 800,
    height: height || 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '90%', 
    overflowY: 'auto',
  };

  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
              sx={{
                  display: 'flex',
                  justifyContent: 'space-between',  // Added this
                  alignItems: 'center',
                  background: 'linear-gradient(45deg, #ff5c00, #2a2a2a)', // Smooth gradient
                  color: '#fff',  
                  padding: '1rem 2rem',  // Horizontal and vertical padding
                  borderRadius: '4px',  // Soft border radius
                  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',  // Subtle shadow
                  marginBottom: '1rem'                    
              }}
          >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Person sx={{ marginRight: '0.5rem', color: '#fff' }} />
                  {modalTitle}
              </div>
              {modalTitleRight}
          </Typography>         

            <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />

            {children}

            <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />

            <Grid container justifyContent="flex-end" sx={{ mt: 4 }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mr: 2 }}
                  startIcon={<SaveSharp />}
                  onClick={handleSave}
                  className={classes.saveButton}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  startIcon={<CloseSharp />}
                  onClick={handleClose}
                  className={classes.closeButton}
                >
                  Close
                </Button>
            </Grid>
        </Box>
      </Modal>
    </div>
  );
};

CustomModal.defaultProps = {
  open: false,
};

export default CustomModal;
