import React, { useState, useEffect } from 'react';
import {PageLayout} from '../components/layout/PageLayout/PageLayout';
import Card from '../components/common/elements/Card/Card';
import Table from '../components/common/elements/Table/Table';
import FormFilter from '../components/common/elements/FormFilter/FormFilter';
import Modal from '../components/common/elements/Modal/Modal';
import TextField from '@mui/material/TextField';
import { fetchData, postData } from '../services/api';
import SnackbarAlert from '../components/common/elements/SnackbarAlert/SnackbarAlert';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {MODULE_TYPES, IS_UI_COMPONENT_OPTIONS, IS_DEFAULT_OPTIONS} from '../utils/moduleConstants';
import { validateAndSetError } from '../helpers/validationHelpers'; 

const Module = () => {
    const [modalTitle, setModalTitle] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [fullTableData, setFullTableData] = useState([]);    
    const [filterValue, setFilterValue] = useState(''); 

    const [moduleName, setModuleName] = useState('');
    const [moduleDescName, setModuleDescName] = useState('');
    const [moduleController, setModuleController] = useState('');
    const [moduleSequence, setModuleSequence] = useState('');
    const [systemsFilter, setSystemsFilter] = useState([]);
    const [selectedSystemFilter, setSelectedSystemFilter] = useState(null);
    const [systems, setSystems] = useState([]);
    const [modules, setModules] = useState([]);
    const [modulesChild, setModulesChild] = useState([]);
    const [selectedSystem, setSelectedSystem] = useState(null);    
    const [selectedParentModule, setSelectedParentModule] = useState(null);    
    const [selectedParentModuleID, setSelectedParentModuleID] = useState(null);    
    const [selectedChildModule, setSelectedChildModule] = useState(null);    
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedModuleType, setSelectedModuleType] = useState(null);
    const [selectedUiComponent, setSelectedUiComponent] = useState({ label: 'No', value: 'N' });
    const [selectedDefault, setSelectedDefault] = useState({ label: 'No', value: 'N' });

    const [selectedSystemError, setSelectedSystemError] = useState(false);
    const [selectedModuleTypeError, setModuleTypeError] = useState(false);
    const [moduleNameError, setModuleNameError] = useState(false);    
    const [moduleControllerError, setModuleControllerError] = useState(false);
    const [moduleParentError, setModuleParentError] = useState(false);
    const [moduleChildError, setModuleChildError] = useState(false);
    const [selectedUiComponentError, setSelectedUiComponentError] = useState(false);
    const [selectedDefaultError, setSelectedDefaultError] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // or "error", "info", "warning"

    const tableHeadData = ['System', 'Module Name', 'Path', 'Desc','Module Controller', 'Sequence','IsComponent', 'IsDefault', 'Status', 'Action'];

    const performSearch = async () => {
        const selectedSystemId = selectedSystemFilter ? selectedSystemFilter.SystemID : null;

        await fetchData('modules', { filter: filterValue, SystemID: selectedSystemId  })
        .then((result) => {
            if (result.code === 404) {
              setTableData([]); 
              setSnackbarMessage(result.message);
              setSnackbarOpen(true); 
            }else{
              const resultArray = Object.values(result.data).map((item) => {
                const ModulePath = [item.ParentModule, item.ChildModule, item.ModuleName]
                .filter(Boolean)
                .join(' > ');
                
                return {
                  id: item.ModuleID,
                  systemName: item.SystemName,
                  moduleName: item.ModuleName,
                  modulePath: ModulePath,
                  moduleDescription: item.ModuleDescription,
                  moduleController: item.ModuleController,
                  moduleSequence: item.ModuleSequence,
                  isComponent: item.IsComponent,
                  isDefaultSystemController: item.IsDefaultSystemController,
                  status: item.TableStatus,
                  SystemID: item.SystemID,
                  ModuleParentID: item.ModuleParentID,
                  moduleType: item.ModuleType,
                  ChildParentID: item.ChildParentID,
                  SubChildParentID: item.SubChildParentID,
                };
              });              

              setFullTableData(resultArray);
              setTableData(resultArray.map(({ SystemID,ModuleParentID, moduleType, ChildParentID,SubChildParentID, ...rest }) => rest));                              
            }
          })
        .catch((error) => console.error('Error fetching data:', error));
    };

    const performNew = () => {
      setModalTitle('New Module');
      setSelectedSystem(null);
      setSelectedModuleType(null);
      setSelectedParentModule(null);
      setSelectedChildModule(null);
      setModuleName('');
      setModuleDescName('');
      setModuleController('');
      setModuleSequence('');
      setSelectedUiComponent({ label: 'No', value: 'N' });
      setSelectedDefault({ label: 'No', value: 'N' });
      setModules([]);
      setTableData([]); 

      setSelectedSystemError(false);
      setModuleTypeError(false);
      setModuleNameError(false);
      setModuleControllerError(false);
      setModuleParentError(false);
      setModuleChildError(false);
      setSelectedUiComponentError(false);
      setSelectedDefaultError(false);

      setOpenModal(true);
    };
    
    const performClear = () => {
      setSelectedSystem(null); 
      setTableData([]); 
      setFilterValue(''); 
    };

    const handleSequenceChange = (e) => {
      const value = e.target.value;
      
      // Allow only whole numbers
      if (/^\d*$/.test(value)) {
        setModuleSequence(value);
      }
    };

    const handleButtonClick = (rowId) => {
      const row = fullTableData.find((data) => data.id === rowId);      

      if (row) {
          setModalTitle('Update Module');
          setSelectedRowData(row);
          setModuleName(row.moduleName);
          setModuleDescName(row.moduleDescription == null ? '' : row.moduleDescription);
          setModuleController(row.moduleController == null ? '' : row.moduleController);
          setModuleSequence(row.moduleSequence == null ? '' : row.moduleSequence);
          setSelectedParentModule(null);
          setSelectedChildModule(null);

          setSelectedSystem(systems.find((system) => system.SystemID === row.SystemID));
          setSelectedModuleType(MODULE_TYPES.find((moduleType) => moduleType.value === row.moduleType));
          setSelectedUiComponent(IS_UI_COMPONENT_OPTIONS.find((component) => component.value === row.isComponent));
          setSelectedDefault(IS_DEFAULT_OPTIONS.find((component) => component.value === row.isDefaultSystemController));

          if (row.moduleType === 'child' || row.moduleType === 'sub-child') {
            let foundSystem = systems.find((system) => system.SystemID === row.SystemID);

            if (foundSystem) {
              if (row.moduleType === 'child'){
                retrieveSystemModule({ 
                  moduleType: 'child', 
                  action: 'handle-button-click', 
                  systemID: foundSystem.SystemID, 
                  moduleParentID: row.ModuleParentID
                });
              }

              if (row.moduleType === 'sub-child'){
                retrieveSystemModule({ 
                  moduleType: 'child', 
                  action: 'handle-button-click', 
                  systemID: foundSystem.SystemID, 
                  moduleParentID: row.ChildParentID
                });

                retrieveSystemModule({ 
                  moduleType: row.moduleType, 
                  action: 'handle-button-click', 
                  systemID: foundSystem.SystemID, 
                  moduleParentID: row.ChildParentID, 
                  childParentID: row.SubChildParentID 
                });                
              }
            }
          }

          setOpenModal(true);
      }
    };

    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };    

    const handleStatusChange = async ({ newStatus, rowIndex, row }) => {
      const originalData = [...tableData]; 
      const updatedData = [...tableData];
      updatedData[rowIndex].status = newStatus;
    
      const payload = { 
        ReferenceTableStatusID: newStatus === 'Active' ? 1 : 2 
      };

      try {
        await postData('modules/update/status', { ...payload, ModuleID: row.id }); 
        setTableData(updatedData);
      } catch (error) {
        console.error("Failed to update:", error);
        setTableData(originalData);
      }
    }; 

    const handleSaveModal = async () => {
      const systemID = selectedSystem?.SystemID;
      const selectedModuleTypeValue = selectedModuleType?.value;     
      const uiComponent = selectedUiComponent?.value;     
      const isDefault = selectedDefault?.value;     
      let moduleParentID = null;

      if (validateAndSetError(!systemID, setSelectedSystemError)) return;
      if (validateAndSetError(!selectedModuleTypeValue, setModuleTypeError)) return;
      if (validateAndSetError(selectedModuleTypeValue === 'child' && !selectedParentModule, setModuleParentError)) return;
      if (validateAndSetError(selectedModuleTypeValue === 'sub-child' && !selectedChildModule, setModuleChildError)) return;
      if (validateAndSetError(!uiComponent, setSelectedUiComponentError)) return;
      if (validateAndSetError(!isDefault, setSelectedDefaultError)) return;
      if (validateAndSetError(!moduleName, setModuleNameError)) return;

      if (selectedModuleTypeValue === 'parent') {
        moduleParentID = 0;
      }else if (selectedModuleTypeValue === 'sub-child') {
        moduleParentID = selectedChildModule?.ModuleID;
      }else{
        moduleParentID = selectedParentModule?.ModuleID;        
      }

      try {
        const payload = { 
          SystemID: systemID,
          ModuleParentID: moduleParentID,
          IsComponent: uiComponent,
          IsDefaultSystemController: isDefault,
          ModuleName: moduleName,
          ModuleDescription: moduleDescName,
          ModuleController: moduleController,
          ModuleSequence: moduleSequence
        };
        
        const endpoint = selectedRowData ? 'modules/update' : 'modules';
        
        // Get the result first
        const result = await postData(endpoint, {
          ...payload,
          ModuleID: selectedRowData?.id
        });
        
        // Check the code and possibly other properties of the result
        if (result.code === 404 || result.code === 409) {
          setTableData([]); 
          setSnackbarSeverity('warning');
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);   
          return;        
        }

        // Destructure data from result
        const { data } = result;

        // Use the data as needed
        if (data.length > 0) {

          // Destructure fields from the first item in the data array
          const {
            ModuleID: id,
            SystemID: systemID,
            SystemName: systemName,
            ModuleParentID: moduleParentID,
            IsComponent: isComponent,
            IsDefaultSystemController: isDefaultSystemController,
            ModuleName: moduleName,
            '': modulePath,
            ModuleDescription: moduleDescName,
            ModuleController: moduleController,
            ModuleSequence: moduleSequence,
            TableStatus: status,
          } = data[0];

          //Create a new entry with the destructured fields
          const newEntry = {
            id,
            systemName,
            moduleName,
            modulePath,
            moduleDescName,
            moduleController,
            moduleSequence,
            isComponent,
            isDefaultSystemController,
            status
          };

          setFullTableData((prevFullTableData) => {
            if (selectedRowData) {
              return prevFullTableData.map((item) => (item.id === selectedRowData.id ? { 
                ...newEntry, SystemID: selectedSystem?.SystemID, ModuleID: selectedParentModule?.ModuleID, ModuleName: moduleName 
              } : item));
            } else {
              return [...prevFullTableData, { ...newEntry, SystemID: selectedSystem?.SystemID, ModuleID: selectedParentModule?.ModuleID, ModuleName: moduleName }];
            }
          });

          setTableData((prevTableData) => {
            if (selectedRowData) {
              return prevTableData.map((item) => (item.id === selectedRowData.id ? newEntry : item));
            } else {
              return [...prevTableData, newEntry];
            }
          });  
          
          setOpenModal(false);
            
          const action = selectedRowData ? 'updated' : 'added';
          setSnackbarMessage(`Record successfully ${action}.`);
          setSnackbarSeverity('success');            
          setSnackbarOpen(true);           
        }
               

      } catch (error) {
        console.error('Error saving:', error);
      }
    }

    const handleCloseModal = () => {
      setOpenModal(false);
    }; 

    const retrieveSystem = async () => {
      await fetchData('systems', {TableStatusID: 1})
      .then((result) => {
        if (result.code === 404) {
          setTableData([]); 
          setSnackbarMessage(result.message);
          setSnackbarOpen(true); 
        }else{
          setSystems(result['data']);
          setSystemsFilter(result['data']);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));        
    };    

    useEffect(() => {
      retrieveSystem();
    }, []);     

    const retrieveSystemModule = async (param = {}) => {
      let selectModuleTypeValue = selectedModuleType?.value;
      let systemID = selectedSystem?.SystemID;
      let moduleTypeValue = param.moduleType?.value;

      if( param.action === 'handle-button-click' ){
        systemID = param.systemID;
        moduleTypeValue = param.moduleType;
      }
      
      let moduleID = null;

      if (moduleTypeValue === 'child') {
        moduleID = 0;
      }

      switch(param.action){
        case 'module-type': //kung nag on change sa module type
        case 'handle-button-click':
          if (moduleTypeValue === 'sub-child') {
            if( param.action === 'handle-button-click' ){
              moduleID = param.moduleParentID;
            }else{
              moduleID = selectedParentModule?.ModuleID == undefined ? 0 : selectedParentModule?.ModuleID;
            }
          }           
        break;
        case 'module-parent': //kung nag on change sa parent module
          if (selectModuleTypeValue === 'sub-child') {
            moduleID = param.module?.ModuleID;
          }           
        break;        
      }

      if (moduleID !== null){
        await fetchData('systems/module', {SystemID: systemID, ModuleID: moduleID})
        .then((result) => {

          if (result.code === 404) {
            setModulesChild([]); 
            setSelectedChildModule(null);
            setSnackbarMessage(result.message);
            setSnackbarOpen(true); 
          }else{
            if (moduleTypeValue === 'child') {
              setModules(result['data']);
              if( param.action === 'handle-button-click' ){
                let foundModule = result['data'].find((module) => module.ModuleID === param.moduleParentID);                      

                if(foundModule){
                  setSelectedParentModule(foundModule);
                }
              }
              
            }else{
              
              if (moduleID === 0) {
                setModules(result['data']);
              }else{
                setSelectedChildModule(null);
                setModulesChild(result['data']);

                if( param.action === 'handle-button-click' ){
                  let foundModule = result['data'].find((module) => module.ModuleID === param.childParentID);                      

                  if(foundModule){
                    setSelectedChildModule(foundModule);
                  }
                }                
              }
            }
            
          }
        })
        .catch((error) => console.error('Error fetching data:', error));            
      }
    }    

    return (
        <PageLayout sm={3} itemLeftPadding={2} alignItems="flex-start" 
          breadcrumbs={[
              { label: "Home", href: "/" },
              { label: "Setup", href: "#" },
              { label: "Module", href: "#" }
          ]}
        >
        <Card>
            <FormFilter 
                handleSearch={performSearch} 
                handleNew={performNew} 
                handleClear={performClear} 
                filterValue={filterValue} 
                setFilterValue={setFilterValue}               
                showAutocomplete={true}
                options={systemsFilter}
                getOptionLabelKey='SystemNameWithCode'
                renderOption={(props, option) => <li {...props} key={option.SystemID}>{option.SystemNameWithCode}</li>}
                renderInput={(params) => <TextField {...params} label="System Name" />}
                selectedValue={selectedSystemFilter} // Pass the selected value
                onValueChange={setSelectedSystemFilter} // Pass the function to update the selected value    
                autoCompleteWidth='20%'            
            />
        </Card>      
          <div style={{ marginBottom: '20px' }}></div>
          <Table tableHeadData={tableHeadData} tableData={tableData} handleButtonClick={handleButtonClick} onStatusChange={handleStatusChange} />
          <Modal 
                width={800} 
                height={650}
                open={openModal} 
                handleSave={handleSaveModal} 
                handleClose={handleCloseModal} 
                modalTitle={modalTitle}
            >
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Autocomplete 
                      options={systems}
                      getOptionLabel={(option) => option.SystemNameWithCode}
                      onChange={(event, newValue) => { 
                        setSelectedSystem(newValue); 
                        setSelectedModuleType(null);
                        setSelectedParentModule(null);
                        setSelectedChildModule(null);
                        setSelectedSystemError(false);
                      }}
                      value={selectedSystem}
                      isOptionEqualToValue={(option, value) => option && value && option.SystemID === value.SystemID}
                      renderOption={(props, option) => <li {...props} key={option.SystemID}>{option.SystemNameWithCode}</li>}
                      sx={{ width: '100%', mb: 2 }}
                      renderInput={(params) => 
                        <TextField 
                          {...params} 
                          label="System Name" 
                          error={selectedSystemError} 
                          helperText={selectedSystemError ? 'System Name is required' : ''} 
                        />
                      }                      
                  />

                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    clearIcon={null}
                    disabled={!selectedSystem}
                    options={MODULE_TYPES}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      setSelectedParentModule(null);
                      setSelectedModuleType(newValue); 
                      retrieveSystemModule({moduleType: newValue, action: 'module-type'});
                      setModuleTypeError(false);
                    }}
                    value={selectedModuleType}
                    renderOption={(props, option) => <li {...props} key={option.value}>{option.label}</li>}
                    sx={{ width: '100%', mb: 2 }}
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        label="Module Type" 
                        error={selectedModuleTypeError} 
                        helperText={selectedModuleTypeError ? 'You must select a module type' : ''} 
                      />
                    }                                          
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disabled={!selectedSystem || !selectedModuleType || selectedModuleType.value === 'parent' || selectedModuleType.value === ''}
                    options={modules}
                    getOptionLabel={(option) => option.ModuleName}
                    onChange={(event, newValue) => { 
                      setSelectedParentModule(newValue); 
                      retrieveSystemModule({module: newValue, action: 'module-parent'});
                      setModuleParentError(false);
                    }}
                    value={selectedParentModule} 
                    isOptionEqualToValue={(option, value) => option && value && option.ModuleID === value.ModuleID}
                    renderOption={(props, option) => <li {...props} key={option.ModuleID}>{option.ModuleName}</li>}
                    sx={{ width: '100%', mb: 2 }}
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        label="Module Parent" 
                        error={moduleParentError} 
                        helperText={moduleParentError ? 'Module Parent is required' : ''} 
                      />
                    }                    
                  />
                </Grid>  
                <Grid item xs={6}>
                  <Autocomplete
                    disabled={!selectedParentModule || selectedModuleType.value !== 'sub-child'}
                    options={modulesChild}
                    getOptionLabel={(option) => option.ModuleName}
                    onChange={(event, newValue) => { 
                      setSelectedChildModule(newValue); 
                      setModuleChildError(false);
                    }}
                    value={selectedChildModule} 
                    isOptionEqualToValue={(option, value) => option && value && option.ModuleID === value.ModuleID}
                    renderOption={(props, option) => <li {...props} key={option.ModuleID}>{option.ModuleName}</li>}
                    sx={{ width: '100%', mb: 2 }}
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        label="Module Child" 
                        error={moduleChildError} 
                        helperText={moduleChildError ? 'Module Child is required' : ''} 
                      />
                    }                                        
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                      disabled={!selectedSystem || !selectedModuleType || selectedModuleType.value === ''}
                      fullWidth
                      label="Module Name"
                      type="search"
                      placeholder="Enter name here"
                      sx={{ mb: 2 }}
                      required
                      error={moduleNameError}
                      helperText={moduleNameError ? 'Module Name is required' : ''}
                      value={moduleName}
                      onChange={e => setModuleName(e.target.value)}                    
                  /> 
                </Grid>
                <Grid item xs={6}>
                  <TextField
                      disabled={!selectedSystem || !selectedModuleType || selectedModuleType.value === ''}
                      fullWidth
                      label="Module Description"
                      type="search"
                      placeholder="Enter description here"
                      sx={{ mb: 2 }}
                      value={moduleDescName}
                      onChange={e => setModuleDescName(e.target.value)}                    
                  /> 
                </Grid>
                <Grid item xs={6}>
                  <TextField
                      disabled={!selectedSystem || !selectedModuleType || selectedModuleType.value === ''}
                      fullWidth
                      label="Module Controller"
                      type="search"
                      placeholder="Enter name here"
                      sx={{ mb: 2 }}
                      required
                      error={moduleControllerError}
                      helperText={moduleControllerError ? 'Module Controller is required' : ''}
                      value={moduleController}
                      onChange={e => setModuleController(e.target.value)}                    
                  /> 
                </Grid>
                <Grid item xs={6}>
                  <TextField
                      disabled={!selectedSystem || !selectedModuleType || selectedModuleType.value === ''}                  
                      fullWidth
                      label="Module Sequence"
                      type="search"
                      placeholder="Enter sequence here"
                      sx={{ mb: 2 }}
                      value={moduleSequence}
                      onChange={handleSequenceChange}               
                  /> 
                </Grid>   
                <Grid item xs={6}>
                  <Autocomplete
                    clearIcon={null}
                    disabled={!selectedSystem || !selectedModuleType || selectedModuleType.value === ''}
                    options={IS_UI_COMPONENT_OPTIONS}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => { 
                      setSelectedUiComponent(newValue); 
                      setSelectedUiComponentError(false);
                    }}
                    value={selectedUiComponent}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderOption={(props, option) => <li {...props} key={option.value}>{option.label}</li>}
                    sx={{ width: '100%', mb: 2 }}
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        label="Is UI Component" 
                        error={selectedUiComponentError} 
                        helperText={selectedUiComponentError ? 'Is UI Component is required' : ''} 
                      />
                    }                     
                  />
                </Grid>                                                                                             
                <Grid item xs={6}>
                  <Autocomplete
                    clearIcon={null}
                    disabled={!selectedSystem || !selectedModuleType || selectedModuleType.value === ''}
                    options={IS_DEFAULT_OPTIONS}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => { 
                      setSelectedDefault(newValue); 
                      setSelectedDefaultError(false);
                    }}
                    value={selectedDefault}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderOption={(props, option) => <li {...props} key={option.value}>{option.label}</li>}
                    sx={{ width: '100%', mb: 2 }}
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        label="Is Default" 
                        error={selectedDefaultError} 
                        helperText={selectedDefaultError ? 'Is Default is required' : ''} 
                      />
                    }                                         
                  />
                </Grid>                                                                                                             
              </Grid>              
            </Modal>  

            <SnackbarAlert 
                open={snackbarOpen} 
                handleClose={handleSnackbarClose} 
                message={snackbarMessage} 
                severity={snackbarSeverity}
            />                       
        </PageLayout>    
    );
};

export default Module;
