import React,{useState} from 'react';
import classes from './ContentFlightsPriceSlide.module.css';
import arrowLeft from '../../../assets/image/menu-icons/arrow-left.svg';
import arrowRight from '../../../assets/image/menu-icons/arrow-right.svg';

const menu_prices = [
    { id: 1, date: "Sun, 14 Jan", price: "1743" },
    { id: 2, date: "Mon, 15 Jan", price: "1100" },
    { id: 3, date: "Tue, 16 Jan", price: "945" },
    { id: 4, date: "Wed, 17 Jan", price: "10000" },
    { id: 5, date: "Thu, 18 Jan", price: "10500" },
    { id: 6, date: "Fri, 19 Jan", price: "1" },
    { id: 7, date: "Sat, 20 Jan", price: "500" },
    { id: 8, date: "Sat, 20 Jan", price: "500" },
    { id: 9, date: "Sat, 20 Jan", price: "500" },
    { id: 10, date: "Sat, 20 Jan", price: "500" },
    { id: 11, date: "Sat, 20 Jan", price: "500" },
    { id: 12, date: "Sat, 20 Jan", price: "500" },
];

const ContentFlightsPriceSlide = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const cardsToShow = 6; // Number of cards to show at once
    const [activeCard, setActiveCard] = useState(null);

    const displayedPrices = menu_prices
        .slice(currentSlide, currentSlide + cardsToShow)
        .concat(menu_prices.slice(0, Math.max(0, cardsToShow - (menu_prices.length - currentSlide))));

    const handleLeftClick = () => {
        setCurrentSlide(prev => (prev - cardsToShow + menu_prices.length) % menu_prices.length);
    };

    const handleRightClick = () => {
        setCurrentSlide(prev => (prev + cardsToShow) % menu_prices.length);
    };



    const handleCardClick = (id) => {
        setActiveCard(id);
    };
            
    return (
        <div className={classes['container-flights-price-slide']}>
            <div className={classes['arrow-left']} onClick={handleLeftClick}>
                <img src={arrowLeft} alt="arrow left" />
            </div>
            <div className={classes['vertical-divider']}></div>
            <div className={classes['price-card']}>
                {displayedPrices.map(menu_price => (
                    <div key={menu_price.id} 
                    className={`${classes['card-item']} ${activeCard === menu_price.id ? classes['active'] : ''}`}
                    onClick={() => handleCardClick(menu_price.id)}                    
                    >
                        <div>{menu_price.date}</div>
                        <div>₱{menu_price.price}</div>
                    </div>
                ))}
            </div>
            <div className={classes['vertical-divider']}></div>
            <div className={classes['arrow-right']} onClick={handleRightClick}>
                <img src={arrowRight} alt="arrow right" />
            </div>
        </div>
    );
};


export default ContentFlightsPriceSlide;