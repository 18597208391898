import React from 'react';
import classes from './ContentTourActivities.module.css'; 
import close from '../../../assets/image/menu-icons/close-x.svg';
import ContentTourActivitiesData from './ContentTourActivitiesData';

const ContentTourActivities = () => {
  const activities = [
    { id: 1, name: "Adventure" },
    { id: 2, name: "Cultural" },
    { id: 3, name: "Marine" },
    { id: 4, name: "Wild Life" },
    { id: 5, name: "Hiking" },
    { id: 6, name: "Spa & Massage" },
    { id: 7, name: "Adventure" },
    { id: 8, name: "Cultural" },
    { id: 9, name: "Marine" },
    { id: 10, name: "Wild Life" },
    { id: 11, name: "Hiking" },
    { id: 12, name: "Spa & Massage" }
  ];


  return (
    <>
      <div className={classes['container-content-tour-activities']}>
        <aside className={classes.sidebar}>
          <label>Selected</label>

          <div className={classes['selected-holder']}>
            <div className={classes['selected-container']}>
              <div className={classes['selected-value']}>Adventure</div>
              <img src={close} alt="close" />
            </div>
            
            <div className={classes['selected-container']}>
              <div className={classes['selected-value']}>Cultural</div>
              <img src={close} alt="close" />
            </div>

            <div className={classes['selected-container']}>
              <div className={classes['selected-value']}>Marine</div>
              <img src={close} alt="close" />
            </div>          
            <div className={classes['selected-container']}>
              <div className={classes['selected-value']}>Hiking</div>
              <img src={close} alt="close" />
            </div>          
            <div className={classes['selected-container']}>
              <div className={classes['selected-value']}>Spa & Massage</div>
              <img src={close} alt="close" />
            </div>          
            <div className={classes['selected-container']}>
              <div className={classes['selected-value']}>Wild Life</div>
              <img src={close} alt="close" />
            </div>                                        
          </div>
          <div className={classes['clear-selection']}>Clear Selection</div>
          <div className={classes['sidebar-divider']}></div>
          <label>Activities</label>
          <div className={classes['activities-list']}>
            {activities.map(activity => (
              <div key={activity.id} className={classes['activity-item']}>
                <input type="checkbox" id={activity.name + activity.id} />
                <label htmlFor={activity.name + activity.id}>{activity.name}</label>
              </div>
            ))}
          </div>        
        </aside>
        <div className={classes['main-content']}>
          <header className={classes.header}>
            <label>3 results found in Bohol</label>

            <div className={classes['holder-price-range-sort-popular']}>
              <div className={classes.selectContainer}>
                  <select>
                    <option value="">Price range</option>
                      <option value="place1">1000</option>
                      <option value="place2">2000</option>
                      <option value="place3">3000</option>
                  </select>
              </div>    

              <div className={classes['sort-popular-container']}>
                  <select>
                    <option value="">Most popular</option>
                      <option value="place1">1000</option>
                      <option value="place2">2000</option>
                      <option value="place3">3000</option>
                  </select>
              </div>                    
            </div>
          </header>
          <main className={classes.content}>
            <ContentTourActivitiesData />
          </main>
        </div>
        
      </div>
    
    </>
  );
};

export default ContentTourActivities;
