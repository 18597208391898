import React,{useState} from 'react';
import classes from './ContentHotelsData.module.css';
import geolocation from '../../../assets/image/menu-icons/geolocation.svg';
import marriot from '../../../assets/image/hotels/marriot.jpg';
import plantation from '../../../assets/image/hotels/plantation.jpg';
import jpark from '../../../assets/image/hotels/jpark.jpg';
import paradise_bohol from '../../../assets/image/hotels/paradise_bohol.jpg';
import star from '../../../assets/image/menu-icons/star.svg'

const hotelLists = [
    {
      image: marriot,
      location: 'Alegria, Cebu',
      activity: 'Canyoneering',
      hotel_name: 'Marriot Hotel',
      destination_rate: '1k++ booked',
      price: '5000',
      badge: 'Top Seller',
      booked_count: 500,
      facilities: [
        {
            id: 1,
            facility_desc: 'Wifi'
        },
        {
            id: 2,
            facility_desc: 'Swimming Pool'
        },
        {
            id: 3,
            facility_desc: 'Parking'
        },
        {
            id: 4,
            facility_desc: 'Restaurant'
        },
        {
            id: 5,
            facility_desc: 'Sky Adventure'
        },
        {
            id: 6,
            facility_desc: 'Indoor Shooting Range'
        }                                                   
      ]
    },
    {
      image: plantation,
      location: 'Puerto Princesa City',
      activity: 'Piers & Boardwalks',
      hotel_name: 'Plantation Bay',
      destination_rate: '1k++ booked',
      price: '10000',
      badge: 'Special Offer',
      booked_count: 1500,
      facilities: [
        {
            id: 1,
            facility_desc: 'Wifi'
        },
        {
            id: 2,
            facility_desc: 'Swimming Pool'
        },
        {
            id: 3,
            facility_desc: 'Parking'
        },
        {
            id: 4,
            facility_desc: 'Restaurant'
        }                             
      ]      
    },
    {
      image: jpark,
      location: 'Cebu City, Cebu',
      activity: 'Museum',
      hotel_name: 'JPark Hotel',
      destination_rate: '1k++ booked',
      price: '15000',
      badge: 'Top Seller',
      booked_count: 2100,
      facilities: [
        {
            id: 1,
            facility_desc: 'Wifi'
        },
                            
      ]      
    },
    {
      image: paradise_bohol,
      location: 'Albay',
      activity: 'Historical landmark',
      hotel_name: 'Bohol Paradise',
      destination_rate: '1k++ booked',
      price: '25000',
      badge: 'Special Offer',
      booked_count: 5000,
      facilities: [
        {
            id: 1,
            facility_desc: 'Wifi'
        },
        {
            id: 2,
            facility_desc: 'Swimming Pool'
        },
                            
      ]      
    },
    {
        image: paradise_bohol,
        location: 'Albay',
        activity: 'Historical landmark',
        hotel_name: 'Bohol Paradise',
        destination_rate: '1k++ booked',
        price: '25000',
        badge: 'Special Offer',
        booked_count: 5000,
        facilities: [
          {
              id: 1,
              facility_desc: 'Wifi'
          },
          {
              id: 2,
              facility_desc: 'Swimming Pool'
          },
                              
        ]      
      },     
  ];
  
  const getStarRating = (bookingRate) => {
    if (bookingRate < 1000) return 1;
    if (bookingRate >= 1000 && bookingRate < 1500) return 2;
    if (bookingRate >= 1500 && bookingRate < 2000) return 3;
    if (bookingRate >= 2000 && bookingRate < 2500) return 4;
    if (bookingRate >= 2500) return 5;
    return 0; 
  };
  

const ContentHotelsData = () => {

    return (
        <div className={classes['holder-content-hotel-data']}>
            <div className={classes.hotelList}>
            {hotelLists.map((dest, index) => (
                <div key={index} className={classes.hotelCard}>
                    <img src={dest.image} alt={dest.place} className={classes.hotelImage} />
                    <div className={classes.hotelInfo}>
                        <p>
                            <img src={geolocation} alt="geolocation" />
                            {dest.activity} • {dest.location}
                        </p>
                        <p>{dest.hotel_name}</p>
                        <p>
                            {Array(getStarRating(dest.booked_count)).fill().map((_, idx) => (
                                <img key={idx} className={classes.star} src={star} alt="star" />
                            ))}                  
                            <span className={classes.bookedCount}>
                                {dest.booked_count}++ • booked
                            </span>                            
                        </p>
                        <p className={classes.hotelPrice}>₱{dest.price}</p>
                        <div className={classes.hotelFacilities}>
                            {dest.facilities.map(facility => (
                                <span key={facility.id} className={classes.facility}>
                                    {facility.facility_desc}
                                </span>
                            ))}
                        </div>                    



                    </div>
                </div>
            ))}
            </div>

        </div>
    );
};

export default ContentHotelsData;

