import Dashboard from '@mui/icons-material/Dashboard';
import Group from '@mui/icons-material/Group';
import Person from '@mui/icons-material/Person';
import Security from '@mui/icons-material/Security';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SaveAlt from '@mui/icons-material/SaveAlt';
import CorporateFare from '@mui/icons-material/CorporateFare';
import Microsoft from '@mui/icons-material/Microsoft';
import SettingsApplications from '@mui/icons-material/SettingsApplications';
import {SearchSharp, AddCircleOutlineSharp, RestartAltSharp} from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export const DashboardIcon = () => <Dashboard />;
export const GroupIcon = () => <Group />;
export const SecurityIcon = () => <Security />;
export const ExpandLessIcon = () => <ExpandLess />;
export const ExpandMoreIcon = () => <ExpandMore />;
export const PersonIcon = () => <Person />;
export const SaveAltIcon = () => <SaveAlt />;
export const CorporateFareIcon = () => <CorporateFare />;
export const MicrosoftIcon = () => <Microsoft />;
export const SettingsApplicationsIcon = () => <SettingsApplications />;
export const SearchSharpIcon = () => <SearchSharp />;
export const AddCircleOutlineSharpIcon = () => <AddCircleOutlineSharp />;
export const RestartAltSharpIcon = () => <RestartAltSharp />;
export const ModeEditIconIcon = () => <ModeEditIcon />;

