import React, { useState } from 'react';
import { saveToken, saveUserInfo  } from '../services/storage/indexedDB';
import classes from './LoginScreen.module.css';
import { verifyLogin } from '../services/api';


export const LoginScreen = ({ onSubmit }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      // Validate the username and password
      const validationErrors = {};
  
      if (username.trim() === '') {
        validationErrors.username = 'Please enter a username.';
      }
  
      if (password.trim() === '') {
        validationErrors.password = 'Please enter a password.';
      }
  
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
  
      try {
          const response = await verifyLogin('token', {username,password, include_user_info: true})
          const access_token = response.access_token;
          const user_info = response.user_info;
  
          // Save the token to IndexedDB
          await saveToken(access_token);
  
          await saveUserInfo(user_info);        
          // Reset the form and error state
          setUsername('');
          setPassword('');
          setErrors({});
          setErrorMessage('');
  
          // Call the onSubmit callback with isAuthenticated set to true
          onSubmit(true);
      } catch (error) {
        console.error('Login failed:', error);
        setErrorMessage(error.response?.data?.message || 'An error occurred during login.');
      }
    };
  
    const handleUsernameChange = (event) => {
      setUsername(event.target.value);
      setErrors((prevErrors) => ({ ...prevErrors, username: '' }));
      setErrorMessage('');
    };
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
      setErrors((prevErrors) => ({ ...prevErrors, password: '' }));
      setErrorMessage('');
    };
  
    return (
      <div className={classes['background']}>
        <div className={classes['background1']}>
          <div className={classes['login-screen']}>
            <h2>Welcome to Travel Master Club!</h2>
            <small>Experience the joy of travel</small>
            <form onSubmit={handleSubmit}>
              {errors.username && <p className={classes.error}>{errors.username}</p>}
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={handleUsernameChange}
              />
              {errors.password && <p className={classes.error}>{errors.password}</p>}
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              />
              <button type="submit" className={classes['login-button']}>
                Login
              </button>
              {errorMessage && (
                <p className={classes['error-message']}>{errorMessage}</p>
              )}
            </form>
          </div>
        </div>
      </div>
      
    );
  };