import React, {useEffect, useState} from 'react';
import classes from './PublicHeader.module.css';
import Logo from '../../../assets/image/logo@2x.png';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { LOGIN_PAGE } from '../../../utils/constants';
import { FaBars, FaTimes } from 'react-icons/fa';


const FlagOption = ({ innerProps, isDisabled, data }) => {
  if (isDisabled) {
    return null;
  }

  return (
    <div {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
      {data.flag && <img src={data.flag} alt={`${data.label} flag`} style={{ width: '20px', height: '15px', marginRight: '10px' }} />}
      <span>{data.label}</span>
    </div>
  );
};

const PublicHeader = () => {
  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then(response => response.json())
      .then(data => {
        const options = data.map(country => ({
          value: country.currencies ? Object.keys(country.currencies)[0] : '',
          label: country.name.common,
          flag: `https://flagcdn.com/${country.cca2.toLowerCase()}.svg`, 
        }));
        setCountries(options);
  
        const philippines = options.find(option => option.label === 'Philippines');
        setSelectedOption(philippines);
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ced4da',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #adb5bd',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid #e9ecef',
      color: state.isSelected ? '#007bff' : '#495057',
      backgroundColor: state.isFocused ? '#f8f9fa' : '#fff',
    }),
  };

  return (
    <div className={classes.header}>
      <img src={Logo} alt='Logo' className={classes.logo} onClick={() => navigate('/')} />

      <button className={classes.hamburger} onClick={handleHamburgerClick}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </button>

      {isMenuOpen && (
        <div className={classes.verticalMenu}>
          <Select
            value={selectedOption}
            onChange={setSelectedOption}
            options={countries}
            styles={customStyles}
            getOptionLabel={(option) => option.label}
            components={{ Option: FlagOption }}
          />
          <span className={classes.questionsLabel}>Questions?</span>
          <a href="/signup" className={classes.signupLink}>Signup</a>
          <a href={LOGIN_PAGE} className={classes.loginLink}>Login</a>
        </div>
      )}
      
      <div className={classes.loginSection}>
        <Select
          value={selectedOption}
          onChange={setSelectedOption}
          options={countries}
          styles={customStyles}
          getOptionLabel={(option) => option.label}
          components={{ Option: FlagOption }}
        />        
        <span className={classes.questionsLabel}>Questions?</span>
        <a href="/signup" className={classes.signupLink}>Signup</a>
        <a href={LOGIN_PAGE} className={classes.loginLink}>Login</a>
      </div>
    </div>
  );
};

export default PublicHeader;



