import React from 'react';
import classes from './PublicFooter.module.css';
import { FaFacebook, FaYoutube, FaInstagram, FaTwitter, FaTiktok } from 'react-icons/fa';
import bpiLogo from '../../../assets/image/payment-methods/bpi-logo.svg';
import gcashLogo from '../../../assets/image/payment-methods/gcash-logo.svg';
import mastercardLogo from '../../../assets/image/payment-methods/mastercard-logo.svg';
import mayaLogo from '../../../assets/image/payment-methods/maya-logo.svg';
import unionbankLogo from '../../../assets/image/payment-methods/unionbank-logo.svg';
import visaLogo from '../../../assets/image/payment-methods/visa-logo.svg';


const PublicFooter = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.footerColumn}>
        <h4>About Travel Master Club</h4>
        <ul className={classes.bulletList}>
          <li><a href="/about-us" target="_blank" rel="noopener noreferrer">About us</a></li>
          <li><a href="/meet-the-team" target="_blank" rel="noopener noreferrer">Meet the team</a></li>
          <li><a href="/contact-us" target="_blank" rel="noopener noreferrer">Contact us</a></li>
        </ul>
      </div>

      <div className={classes.footerColumn}>
        <h4>Categories</h4>
        <ul className={classes.bulletList}>
          <li>Tours & Activities</li>
          <li>Flights</li>
          <li>Hotels</li>
        </ul>
      </div>

      <div className={classes.footerColumn}>
        <h4>Payment methods</h4>
        <div className={classes.paymentMethods}>
          <img src={bpiLogo} alt="BPI" className={classes.paymentLogo} />
          <img src={gcashLogo} alt="GCash" className={classes.paymentLogo} />
          <img src={mastercardLogo} alt="MasterCard" className={classes.paymentLogo} />
          <img src={mayaLogo} alt="Maya" className={classes.paymentLogo} />
          <img src={unionbankLogo} alt="UnionBank" className={classes.paymentLogo} />
          <img src={visaLogo} alt="Visa" className={classes.paymentLogo} />
        </div>
      </div>

      <div className={classes.footerColumn}>
        <h4>Follow us on</h4>
        <ul>
          <li>
            <a href="https://www.facebook.com/travelmasterclub2023" target="_blank" rel="noopener noreferrer">
              <FaFacebook /> Facebook
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/@TravelMasterClub" target="_blank" rel="noopener noreferrer">
              <FaYoutube /> Youtube
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/travelmasterclub/" target="_blank" rel="noopener noreferrer">
              <FaInstagram /> Instagram
            </a>
          </li>
          <li>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter /> Twitter
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@travelmasterclub" target="_blank" rel="noopener noreferrer">
              <FaTiktok /> Tiktok
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PublicFooter;
