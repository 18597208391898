import React,{useState} from 'react';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import ExploreMenu from './ExploreMenu';
import TourByInterest from './TourByInterest';
import PopularDestinations from './PopularDestinations';
import classes from './Booking.module.css';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import card from '../../assets/image/travel-packages/card@2x.png';
import card1 from '../../assets/image/travel-packages/card1@2x.png';
import card2 from '../../assets/image/travel-packages/card2@2x.png';
import card3 from '../../assets/image/travel-packages/card3@2x.png';
import card4 from '../../assets/image/travel-packages/card4@2x.png';
import card5 from '../../assets/image/travel-packages/card5@2x.png';
import card6 from '../../assets/image/travel-packages/card6@2x.png';

const travelPackageList = [
  card,
  card1,
  card2,
  card3,
  card4,
  card5,
  card6,
];


const faqs = [
  {
    question: "What is a tour?",
    answer: "A tour is a planned journey or travel experience that involves visiting various destinations, attractions, or points of interest. It is usually led by a tour guide or conducted by a travel company to provide travelers with a structured and organized way to explore a specific area or theme."
  },
  {
    question: "What types of tours are available?",
    answer: "We offer a variety of tours including cultural tours, adventure tours, nature and wildlife tours, city tours, historical site tours, culinary experiences, and family-friendly activities."
  },
  {
    question: "How do I book a tour?",
    answer: "You can book a tour directly through our website, by phone, or by visiting our office. We recommend booking in advance to ensure availability, especially during peak travel seasons."
  },
  {
    question: "What is included in a tour package?",
    answer: "Our tour packages typically include transportation, guided tours, entrance fees to attractions, and meals as stated in the itinerary. Accommodations may also be included for multi-day tours. Please refer to the specific tour details for more information."
  },
  {
    question: "Are tours suitable for solo travelers?",
    answer: "Absolutely! Our tours cater to a variety of group sizes, including solo travelers. It's a great way to meet new people while exploring new destinations."
  },
  {
    question: "Can I customize my tour?",
    answer: "Yes, we offer customizable tours. Please contact us to discuss your preferences and needs, and we will do our best to accommodate them."
  },
  {
    question: "What should I bring on a tour?",
    answer: "We recommend comfortable clothing, appropriate footwear for walking, a hat, sunscreen, a water bottle, and any personal medications. Specific tours may have additional requirements which will be communicated in advance."
  },
  {
    question: "What is your cancellation policy?",
    answer: "Cancellations made X days in advance are fully refundable. Cancellations made less than X days in advance will incur a cancellation fee. Please refer to our terms and conditions for detailed information."
  }, 
  {
    question: "Are there any age or health restrictions for tours?",
    answer: "Some tours may have age or physical fitness requirements due to the nature of activities involved. Please check the specific tour details or contact us for more information."
  },
  {
    question: "What happens in case of bad weather?",
    answer: "In case of inclement weather, tours may be rescheduled or canceled for safety reasons. If a tour is canceled, you will have the option to reschedule or receive a full refund."
  },
  {
    question: "Do you offer group discounts?",
    answer: "Yes, we offer discounts for large groups. Please contact us with your group size for more details and pricing."
  },         
];

const Booking = () => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveQuestionIndex(activeQuestionIndex === index ? null : index);
  };

  return (
    <div className={classes.container}>
      <PublicHeader />
      <ExploreMenu />
      <div className={classes.sliderSection}>
        <div className={classes.sliderContent}>
          <h2 className={classes.mainHeading}>Travel made easy</h2>
          <p className={classes.subHeading}>
            "Experience hassle-free travel with Travel Master Club: Book now for <br/>unforgettable adventures!"
          </p>
          <button className={classes.bookNowButton}>Book Now</button>
        </div>
      </div> 
      <div className={classes.travelExperienceSection}>
        <h2 className={classes.travelExperienceTitle}>Secure your travel experience now!</h2>
        <div className={classes.packageTourSlider}>
          <FiChevronLeft className={classes.arrowLeftTravel} size={30} />
          <div className={classes.packageTourSlides}>
            {travelPackageList.map((image, index) => (
              <img key={index} src={image} alt={`Tour Package ${index + 1}`} className={classes.packageTourImage} />
            ))}
          </div>
          <FiChevronRight className={classes.arrowRightTravel} size={30} />
        </div>
      </div>
      <PopularDestinations />
      <TourByInterest title="Tours by Interest" />
      <div className={classes.faqSection}>
        <h2 className={classes.faqTitle}>Frequently Asked Questions (FAQs)</h2>
        {faqs.map((faq, index) => (
          <div key={index} className={classes.faqItem}>
            <button className={classes.faqQuestion} onClick={() => toggleFAQ(index)}>
              {faq.question}
            </button>
            <div className={`${classes.faqAnswer} ${activeQuestionIndex === index ? classes.active : ''}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>   
      <PublicFooter />   
    </div>
  );
};

export default Booking;

