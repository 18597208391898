import React,{useState} from 'react';
import classes from './SearchBooking.module.css';
import TabMenu from './TabMenu';
import PublicHeader from '../../../components/layout/Header/PublicHeader';
import PublicFooter from '../../../components/layout/Footer/PublicFooter';


const SearchBooking = () => {
    return (
        <div className={classes.container}>
            <PublicHeader />

            <TabMenu />

            <PublicFooter />
        </div>
    );
};

export default SearchBooking;

