import React from 'react';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import classes from './AboutUs.module.css';

const AboutUs = () => {
  return (
    <>
        <PublicHeader />
            <div className={classes.aboutUsContainer}>
                <section className={classes.aboutUsBanner}>
                    <div className={classes.aboutUsContent}>
                    <h1 className={classes.aboutUsHeading}>About Us</h1>
                    <p className={classes.aboutUsSubHeading}>Learn more about our mission and values.</p>
                    <button className={classes.aboutUsButton}>Join Our Team</button>
                    </div>
                </section>
                
                <section className={classes.missionVisionSection}>
                    <div className={classes.mission}>
                        <h2 className={classes.sectionTitle}>Our Mission</h2>
                        <p className={classes.sectionContent}>We aim to make traveling easy and affordable, helping everyone explore the world and create unforgettable memories.</p>
                        </div>
                        <div className={classes.vision}>
                        <h2 className={classes.sectionTitle}>Our Vision</h2>
                        <p className={classes.sectionContent}>Our vision is to bring people together by making every dream trip possible, creating lasting stories, and opening up the world for everyone to explore.</p>
                    </div>
                </section>
            </div>

        <PublicFooter />
    </>
  );
};

export default AboutUs;
