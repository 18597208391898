import { create } from 'zustand';

const useStore = create(set => ({
    menus: [],
    setMenus: (menuData) => set({ menus: menuData }),
    isAuthenticated: false,
    setIsAuthenticated: (authStatus) => set({ isAuthenticated: authStatus })
}));

export default useStore;
