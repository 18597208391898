import React from 'react';
import classes from './Dashboard.module.css';
import referralIcon from '../assets/image/referrals.png';
import unilevelIcon from '../assets/image/unilevel.png';
import incomeIcon from '../assets/image/income.png';

const Dashboard = () => {
  return (
    <div className={classes['container-dashboard']}>
      <h2 className={classes['weekly-income-label']}>This week income: Monday January 8, 2024 - Sunday January 14, 2024</h2>
      <div className={classes['container-weekly-income']}>
        <div className={classes['income-card']} id={classes['referral-bonus']}>
          <div className={classes['income-header']}> 
            <img src={referralIcon} alt="Referral" className={classes['icon']}/>
            <div>
              <span className={classes['amount']}>₱ 2,000</span>
              <span className={classes['income-type']}>REFERRAL / CYCLE BONUS</span>
            </div>
          </div>
          <a href="#" className={classes['more-info']}>More info</a>
        </div>
        <div className={classes['income-card']} id={classes['unilevel']}>
          <div className={classes['income-header']}> 
            <img src={unilevelIcon} alt="Unilevel" className={classes['icon']}/>
            <div>
              <span className={classes['amount']}>₱ 2,500</span>
              <span className={classes['income-type']}>UNILEVEL</span>
            </div>
          </div>          
          <a href="#" className={classes['more-info']}>More info</a>
        </div>          
        <div className={classes['income-card']} id={classes['weekly-income']}>
          <div className={classes['income-header']}> 
            <img src={incomeIcon} alt="Weekly income" className={classes['icon']}/>
            <div>
              <span className={classes['amount']}>₱ 4,500</span>
              <span className={classes['income-type']}>WEEKLY INCOME</span>
            </div>
          </div>


          <a href="#" className={classes['more-info']}>More info</a>
        </div>             
      </div>

      <div className={classes['container-total-income']}>
        <div className={classes['total-balance']}>
          <span>TOTAL BALANCE</span>
          <span>₱ 12,000.00</span>
          <a href="#" className={classes['transaction-button']}>Transfer</a>
          <a href="#" className={classes['transaction-button']}>Withdrawal</a>
        </div>

        <div className={classes['membership-balance']}>
          <span>MEMBERSHIP BALANCE</span>
          <span>₱ 12,000.00</span>
          <a href="#" className={classes['transaction-button']}>Transfer</a>
          <a href="#" className={classes['transaction-button']}>Receipt Deposit</a>
          <a href="#" className={classes['transaction-button']}>PC Deposit</a>
        </div>        

        <div className={classes['travel-fund-balance']}>
          <span>TRAVEL FUND BALANCE</span>
          <span>₱ 12,000.00</span>
          <a href="#" className={classes['transaction-button']}>Receipt Deposit</a>
          <a href="#" className={classes['transaction-button']}>PC Deposit</a>
        </div> 

        <div className={classes['travel-fund-balance']}>
          <span>SUBSCRIPTION STATUS</span>
          <span>Active</span>
          <a href="#" className={classes['transaction-button']}>Renew Membership</a>
        </div>

      </div>
      
    </div>
  );
};

export default Dashboard;
