import React, { useState, useEffect } from 'react';
import {PageLayout} from '../components/layout/PageLayout/PageLayout';
import Card from '../components/common/elements/Card/Card';
import Table from '../components/common/elements/Table/Table';
import FormFilter from '../components/common/elements/FormFilter/FormFilter';
import Modal from '../components/common/elements/Modal/Modal';
import TextField from '@mui/material/TextField';
import { fetchData, postData } from '../services/api';
import Autocomplete from '@mui/material/Autocomplete';
import SnackbarAlert from '../components/common/elements/SnackbarAlert/SnackbarAlert';


const Group = () => {
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterValue, setFilterValue] = useState(''); 
  const [groupName, setGroupName] = useState('');
  const [groupNameError, setGroupNameError] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [mainGroups, setMainGroups] = useState([]);
  const [selectedMainGroup, setSelectedMainGroup] = useState(null);
  const [fullTableData, setFullTableData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // or "error", "info", "warning"
  
  const tableHeadData = ['Main Group', 'Group Name', 'Code', 'Status', 'Action'];

  const retrieveMainGroups = async () => {
    await fetchData('groups/main')
    .then((result) => {
        if (result.code === 404) {
          setTableData([]); 
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);              
        }else{
          setMainGroups(result['data']);
        }      

    })
    .catch((error) => console.error('Error fetching data:', error));        
  };
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleStatusChange = async ({ newStatus, rowIndex, row }) => {
    const originalData = [...tableData]; 
    const updatedData = [...tableData];
    updatedData[rowIndex].status = newStatus;

    const payload = { 
      ReferenceTableStatusID: newStatus === 'Active' ? 1 : 2 
    };
  
    try {
      await postData('groups/status/update', { ...payload, GroupID: row.id }); 
      setTableData(updatedData);
    } catch (error) {
      console.error("Failed to update:", error);
      setTableData(originalData);
    }
  };

  const handleButtonClick = (rowId) => {
    const row = fullTableData.find((data) => data.id === rowId);      

    if (row) {
        setSelectedRowData(row);
        setGroupName(row.name);
        setSelectedMainGroup(mainGroups.find((mainGroup) => mainGroup.UserMainGroupID === row.UserMainGroupID));
        setOpenModal(true);
    }    
  }

  const performSearch = async () => {
    await fetchData('groups', { filter: filterValue })
    .then((result) => {
        if (result.code === 404) {
          setTableData([]); 
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);              
        } else {
            const resultArray = Object.values(result.data).map((item) => ({
              id: item.UserGroupID,
              mainGroup: item.UserMainGroupName,
              name: item.UserGroupName,
              code: item.UserGroupCode,
              status: item.TableStatus,
              UserMainGroupID: item.UserMainGroupID
            }));

            setFullTableData(resultArray);
            setTableData(resultArray.map(({ UserMainGroupID, ...rest }) => rest));                

        }          
      })
      .catch((error) => console.error('Error fetching data:', error));
  };

  const performNew = () => {
    setGroupName('');
    setSelectedRowData(null); 
    setSelectedMainGroup(null);
    setOpenModal(true);    
  }

  const performClear = () => {
    setTableData([]); 
    setFilterValue(''); 
  };    

  const handleSaveModal = async () => {
    setGroupNameError(!groupName);
  
    if (!groupName) return;
  
    try {
      const payload = {
        GroupName: groupName,
        MainGroupID: selectedMainGroup?.UserMainGroupID
      };
  
      const endpoint = selectedRowData ? 'groups/update' : 'groups';
      const sendPayload = selectedRowData
        ? { ...payload, GroupID: selectedRowData.id }
        : payload; 

      const { data } = await postData(endpoint, sendPayload);
  
      if (data.length > 0) {
        const { UserGroupID: id, UserGroupCode: code, UserGroupName: name, UserMainGroupName: mainGroup, TableStatus: status } = data[0];
        const newEntry = { id, mainGroup, name, code, status };
  
        if (selectedRowData) {
          const updatedTableData = [...fullTableData];
          const foundIndex = updatedTableData.findIndex(item => item.id === selectedRowData.id);
          updatedTableData[foundIndex] = { ...newEntry, UserMainGroupID: selectedMainGroup?.UserMainGroupID };
          setFullTableData(updatedTableData);
        } else {
          setFullTableData([...fullTableData, { ...newEntry, UserMainGroupID: selectedMainGroup?.UserMainGroupID }]);
        }

        setTableData((prevTableData) => {
          if (selectedRowData) {
            return prevTableData.map((item) => (item.id === selectedRowData.id ? newEntry : item));
          } else {
            return [...prevTableData, newEntry];
          }
        });
  
        setOpenModal(false);
        const action = selectedRowData ? 'updated' : 'added';
        setSnackbarMessage(`Record successfully ${action}.`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error saving:', error);
    }
  };
  
  const handleCloseModal = () => {
    setOpenModal(false);
  };    

  useEffect(() => {
    retrieveMainGroups();
  }, []); 
  
  return (
    <PageLayout
      breadcrumbs={[
        { label: "Home", href: "/" },
        { label: "Settings", href: "#" },
        { label: "Group", href: "#" },
      ]}
    >
      <Card>
          <FormFilter 
            handleSearch={performSearch} 
            handleNew={performNew} 
            handleClear={performClear} 
            filterValue={filterValue} 
            setFilterValue={setFilterValue}            
          />
      </Card>
      <div style={{ marginBottom: '20px' }}></div>
      <Table tableHeadData={tableHeadData} tableData={tableData} handleButtonClick={handleButtonClick} onStatusChange={handleStatusChange} />        
      <Modal 
          width={400} 
          open={openModal} 
          handleSave={handleSaveModal} 
          handleClose={handleCloseModal} 
          modalTitle="Group" 
      >
          <Autocomplete
              disablePortal
              options={mainGroups}
              getOptionLabel={(option) => option.UserMainGroupName}
              onChange={(event, newValue) => { setSelectedMainGroup(newValue); }}
              value={selectedMainGroup} 
              renderOption={(props, option) => <li {...props} key={option.UserMainGroupID}>{option.UserMainGroupName}</li>}
              sx={{ width: '100%', mb: 2 }}
              renderInput={(params) => <TextField {...params} label="Main Group" />}
          />

          <TextField
              fullWidth
              label="Group Name"
              type="search"
              placeholder="Enter name here"
              sx={{ mb: 2 }}
              required
              error={groupNameError}
              helperText={groupNameError ? 'GroupName is required' : ''}
              value={groupName}
              onChange={e => setGroupName(e.target.value)}                    
          /> 
      </Modal>   

      <SnackbarAlert 
        open={snackbarOpen} 
        handleClose={handleSnackbarClose} 
        message={snackbarMessage} 
        severity={snackbarSeverity}
      />      
    </PageLayout>
  );
};

export default Group;



// import React, { useState, useEffect } from 'react';
// import {PageLayout} from '../components/layout/PageLayout/PageLayout';
// import Card from '../components/common/elements/Card/Card';
// import Table from '../components/common/elements/Table/Table';
// import FormFilter from '../components/common/elements/FormFilter/FormFilter';
// import Modal from '../components/common/elements/Modal/Modal';
// import TextField from '@mui/material/TextField';
// import { fetchData, postData } from '../services/api';
// import Autocomplete from '@mui/material/Autocomplete';
// import SnackbarAlert from '../components/common/elements/SnackbarAlert/SnackbarAlert';


// const Group = () => {
//   const [openModal, setOpenModal] = useState(false);
//   const [tableData, setTableData] = useState([]);
//   const [filterValue, setFilterValue] = useState(''); 
//   const [groupName, setGroupName] = useState('');
//   const [groupNameError, setGroupNameError] = useState(false);
//   const [selectedRowData, setSelectedRowData] = useState(null);
//   const [mainGroups, setMainGroups] = useState([]);
//   const [selectedMainGroup, setSelectedMainGroup] = useState(null);
//   const [fullTableData, setFullTableData] = useState([]);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // or "error", "info", "warning"
  
//   const tableHeadData = ['Main Group', 'Group Name', 'Code', 'Status', 'Action'];

//   const retrieveMainGroups = async () => {
//     await fetchData('groups/main')
//     .then((result) => {
//         if (result.code === 404) {
//           setTableData([]); 
//           setSnackbarMessage(result.message);
//           setSnackbarOpen(true);              
//         }else{
//           setMainGroups(result['data']);
//         }      

//     })
//     .catch((error) => console.error('Error fetching data:', error));        
//   };
  
//   const handleSnackbarClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setSnackbarOpen(false);
//   };

//   const handleStatusChange = async ({ newStatus, rowIndex, row }) => {}

//   const handleButtonClick = (rowId) => {
//     const row = fullTableData.find((data) => data.id === rowId);      

//     if (row) {
//         setSelectedRowData(row);
//         setGroupName(row.name);
//         setSelectedMainGroup(mainGroups.find((mainGroup) => mainGroup.UserMainGroupID === row.UserMainGroupID));
//         setOpenModal(true);
//     }    
//   }

//   const performSearch = async () => {
//     await fetchData('groups', { filter: filterValue })
//     .then((result) => {
//         if (result.code === 404) {
//           setTableData([]); 
//           setSnackbarMessage(result.message);
//           setSnackbarOpen(true);              
//         } else {
//             const resultArray = Object.values(result.data).map((item) => ({
//               id: item.UserGroupID,
//               mainGroup: item.UserMainGroupName,
//               name: item.UserGroupName,
//               code: item.UserGroupCode,
//               status: item.TableStatus,
//               UserMainGroupID: item.UserMainGroupID
//             }));

//             setFullTableData(resultArray);
//             setTableData(resultArray.map(({ UserMainGroupID, ...rest }) => rest));                

//         }          
//       })
//       .catch((error) => console.error('Error fetching data:', error));
//   };

//   const performNew = () => {
//     setGroupName('');
//     setSelectedRowData(null); 
//     setSelectedMainGroup(null);
//     setOpenModal(true);    
//   }

//   const performClear = () => {
//     setTableData([]); 
//     setFilterValue(''); 
//   };    

//   const handleSaveModal = async () => {
//     setGroupNameError(!groupName);
  
//     if (!groupName) return;
  
//     try {
//       const payload = {
//         GroupName: groupName,
//         MainGroupID: selectedMainGroup?.UserMainGroupID,
//       };
  
//       const endpoint = selectedRowData ? 'groups/update' : 'groups';
//       const sendPayload = selectedRowData
//         ? { ...payload, GroupID: selectedRowData.id }
//         : payload; // Use payload as is if selectedRowData is null

//       const { data } = await postData(endpoint, sendPayload);
  
//       if (data.length > 0) {
//         const { UserGroupID: id, UserGroupCode: code, UserGroupName: name, UserMainGroupName: mainGroup, TableStatus: status } = data[0];
//         const newEntry = { id, mainGroup, name, code, status };
  
//         setFullTableData((prevFullTableData) => {
//           const updatedTableData = [...prevFullTableData];
//           const foundIndex = updatedTableData.findIndex(item => item.id === selectedRowData.id);
//           if (foundIndex !== -1) {
//             updatedTableData[foundIndex] = { ...newEntry, UserMainGroupID: selectedMainGroup?.UserMainGroupID };
//           }
//           return updatedTableData;
//         });
  
//         setTableData((prevTableData) => {
//           if (selectedRowData) {
//             return prevTableData.map((item) => (item.id === selectedRowData.id ? newEntry : item));
//           } else {
//             return [...prevTableData, newEntry];
//           }
//         });
  
//         setOpenModal(false);
//         const action = selectedRowData ? 'updated' : 'added';
//         setSnackbarMessage(`Record successfully ${action}.`);
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//       }
//     } catch (error) {
//       console.error('Error saving:', error);
//     }
//   };
  

//   const handleCloseModal = () => {
//     setOpenModal(false);
//   };    

//   useEffect(() => {
//     retrieveMainGroups();
//   }, []); 
  
//   return (
//     <PageLayout
//       breadcrumbs={[
//         { label: "Home", href: "/" },
//         { label: "Setup", href: "#" },
//         { label: "Group", href: "/user/group" },
//       ]}
//     >
//       <Card>
//           <FormFilter 
//             handleSearch={performSearch} 
//             handleNew={performNew} 
//             handleClear={performClear} 
//             filterValue={filterValue} 
//             setFilterValue={setFilterValue}            
//           />
//       </Card>
//       <div style={{ marginBottom: '20px' }}></div>
//       <Table tableHeadData={tableHeadData} tableData={tableData} handleButtonClick={handleButtonClick} onStatusChange={handleStatusChange} />        
//       <Modal 
//           width={400} 
//           open={openModal} 
//           handleSave={handleSaveModal} 
//           handleClose={handleCloseModal} 
//           modalTitle="Group" 
//       >
//           <Autocomplete
//               disablePortal
//               options={mainGroups}
//               getOptionLabel={(option) => option.UserMainGroupName}
//               onChange={(event, newValue) => { setSelectedMainGroup(newValue); }}
//               value={selectedMainGroup} 
//               renderOption={(props, option) => <li {...props} key={option.UserMainGroupID}>{option.UserMainGroupName}</li>}
//               sx={{ width: '100%', mb: 2 }}
//               renderInput={(params) => <TextField {...params} label="Main Group" />}
//           />

//           <TextField
//               fullWidth
//               label="Group Name"
//               type="search"
//               placeholder="Enter name here"
//               sx={{ mb: 2 }}
//               required
//               error={groupNameError}
//               helperText={groupNameError ? 'GroupName is required' : ''}
//               value={groupName}
//               onChange={e => setGroupName(e.target.value)}                    
//           /> 
//       </Modal>   

//       <SnackbarAlert 
//         open={snackbarOpen} 
//         handleClose={handleSnackbarClose} 
//         message={snackbarMessage} 
//         severity={snackbarSeverity}
//       />      
//     </PageLayout>
//   );
// };

// export default Group;
