import React, { useState, useEffect } from 'react';
import {PageLayout} from '../components/layout/PageLayout/PageLayout';
import Card from '../components/common/elements/Card/Card';
import Table from '../components/common/elements/Table/Table';
import FormFilter from '../components/common/elements/FormFilter/FormFilter';
import Modal from '../components/common/elements/Modal/Modal';
// import TextField from '@mui/material/TextField';
import { fetchData, postData } from '../services/api';
import Autocomplete from '@mui/material/Autocomplete';
import SnackbarAlert from '../components/common/elements/SnackbarAlert/SnackbarAlert';
import { TextField, Button } from '@mui/material';


const GroupMember = () => {
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [filterValue, setFilterValue] = useState(''); 
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [userGroupInfo, setUserGroupInfo] = useState([]);
    const [selectedUserGroupInfo, setSelectedUserGroupInfo] = useState(null);    
    const [userLoginInfo, setUserLoginInfo] = useState([]);
    const [selectedUserLoginInfos, setSelectedUserLoginInfos] = useState([]);    
    const [fullTableData, setFullTableData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // or "error", "info", "warning"
    const [selectedEffectiveDate, setSelectedEffectiveDate] = useState('');
    const [selectedExpiryDate, setSelectedExpiryDate] = useState('');
    const [isMultipleSelectionAllowed, setIsMultipleSelectionAllowed] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    
    const tableHeadData = ['Profile', 'Group', 'Login','Effective Date','Expiry Date', 'Status', 'Action'];    

    const handleEffectiveDateChange = (event) => {
        setSelectedEffectiveDate(event.target.value);
    };

    const handleExpiryDateChange = (event) => {
        setSelectedExpiryDate(event.target.value);
    };    
    
    const retrieveGroupInfo = async () => {
        await fetchData('users/group/info')
        .then((result) => {
            setUserGroupInfo(result['groups'] || []);
            setUserLoginInfo(result['logins'] || []);
        })
        .catch((error) => console.error('Error fetching data:', error));        
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
    };
    
    const handleStatusChange = async ({ newStatus, rowIndex, row }) => {
        const originalData = [...tableData]; 
        const updatedData = [...tableData];
        updatedData[rowIndex].status = newStatus;
    
        const payload = { 
          ReferenceTableStatusID: newStatus === 'Active' ? 1 : 2 
        };
      
        try {
          await postData('groups/member/update', { ...payload, UserGroupMemberID: row.id }); 
          setTableData(updatedData);
        } catch (error) {
          console.error("Failed to update:", error);
          setTableData(originalData);
        }
    };

    const handleButtonClick = (rowId) => {
        setIsMultipleSelectionAllowed(false);
        const row = fullTableData.find((data) => data.id === rowId);
    
        if (row) {
            const selectedGroup = userGroupInfo.find((groupInfo) => groupInfo.UserGroupID === row.UserGroupID);
            const selectedLogin = userLoginInfo.find((loginInfo) => loginInfo.UserLoginID === row.UserLoginID);
    
            if (selectedGroup && selectedLogin) {
                setSelectedUserGroupInfo(selectedGroup);
                setSelectedUserLoginInfos(selectedLogin);
            } else {
                // Handle the case where the values are not found
                setSelectedUserGroupInfo(null);  // or some default value
                setSelectedUserLoginInfos([]);  // or some default value
            }
            
            setSelectedRowData(row);
            setOpenModal(true);
        } else {
            console.error(`Row with id ${rowId} not found.`);
        }
    };
    
    // const handleButtonClick = (rowId) => {
    //     setIsMultipleSelectionAllowed(false);
    //     const row = fullTableData.find((data) => data.id === rowId);      

    //     if (row) {
    //         console.log('row:',row)
    //         console.log('userGroupInfo:',userGroupInfo)
    //         setSelectedRowData(row);
    //         setSelectedUserGroupInfo(userGroupInfo.find((groupInfo) => groupInfo.UserGroupID === row.UserGroupID));
    //         setSelectedUserLoginInfos(userLoginInfo.find((loginInfo) => loginInfo.UserLoginID === row.UserLoginID));
    //         setOpenModal(true);
    //     }
    // };
    
    const performSearch = async () => {
        await fetchData('groups/member', { filter: filterValue })
        .then((result) => {
            if (result.code === 404) {
              setTableData([]); 
              setSnackbarMessage(result.message);
              setSnackbarOpen(true);              
            } else {
                const resultArray = Object.values(result.data).map((item) => ({
                  id: item.UserGroupMemberID,
                  profileName: item.ProfileName,
                  group: item.Group,
                  userLoginName: item.UserLoginName,
                  effectiveDate: item.EffectiveDate,
                  expiryDate: item.ExpiryDate,
                  status: item.TableStatus,
                  UserGroupID: item.UserGroupID,
                  UserLoginID: item.UserLoginID,
                }));

                setFullTableData(resultArray);
                setTableData(resultArray.map(({ UserGroupID, UserLoginID, ...rest }) => rest));                

            }          
          })
          .catch((error) => console.error('Error fetching data:', error));
    };

    const performNew = () => {
        setSelectedEffectiveDate(''); 
        setSelectedExpiryDate('');
        setIsMultipleSelectionAllowed(true);
        setSelectedRowData(null); 
        setSelectedUserGroupInfo(null);
        setSelectedUserLoginInfos([]);        
        setFormErrors({})
        setOpenModal(true);        
    }
    const performClear = () => {
        setTableData([]); 
        setFilterValue('');         
    }
    const handleSaveModal = async () => {
        try {
            let errors = {};
            
            // Validation for Group
            if (!selectedUserGroupInfo) {
                errors.selectedUserGroupInfo = 'Group is required';
            }
    
            // Validation for Logins
            if (!selectedUserLoginInfos || selectedUserLoginInfos.length === 0) {
                errors.selectedUserLoginInfos = 'At least one login is required';
            }

            // Validation for Effective Date
            if (!selectedEffectiveDate) {
                errors.selectedEffectiveDate = 'Effective Date is required';
            }            
    
            if (Object.keys(errors).length > 0) {
                setFormErrors(errors);
                return; // Stop execution if validation fails
            }

            const payload = {
                userGroupInfo: selectedUserGroupInfo,
                userLoginInfos: selectedUserLoginInfos,
                EffectiveDate: selectedEffectiveDate,
                ExpiryDate: selectedExpiryDate,
            };
    
            const endpoint = selectedRowData ? 'groups/member/update' : 'groups/member';
            const sendPayload = selectedRowData
            ? { ...payload, UserGroupMemberID: selectedRowData.id }
            : payload; 
    
            const { data } = await postData(endpoint, sendPayload);   

            if (data.length > 0) {
                
                const { 
                    UserGroupMemberID: id, 
                    ProfileName: profileName, 
                    Group: group, 
                    UserLoginName: userLoginName, 
                    EffectiveDate: effectiveDate, 
                    ExpiryDate: expiryDate, 
                    TableStatus: status } = data[0];
                const newEntry = { id, profileName, group, userLoginName, effectiveDate,expiryDate,status };

                if (selectedRowData) {
                    const updatedTableData = [...fullTableData];
                    const foundIndex = updatedTableData.findIndex(item => item.id === selectedRowData.id);
                    updatedTableData[foundIndex] = { ...newEntry, UserGroupMemberID: selectedRowData.id };

                    setFullTableData(updatedTableData);
                }
    
                setTableData((prevTableData) => {
                    if (selectedRowData) {
                        return prevTableData.map((item) => (item.id === selectedRowData.id ? newEntry : item));
                    }
                });


                setOpenModal(false);
                const action = selectedRowData ? 'updated' : 'added';
                setSnackbarMessage(`Record successfully ${action}.`);
                setSnackbarSeverity('success');
                setSnackbarOpen(true);                
                retrieveGroupInfo();
                performSearch();
            }

        } catch (error) {
            console.error('Error saving:', error);
        }            
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    }

    useEffect(() => {
        retrieveGroupInfo();
    }, []); 

    return (
        <PageLayout
            breadcrumbs={[
                { label: "Home", href: "/" },
                { label: "Settings", href: "#" },
                { label: "GroupMember", href: "#" },
            ]}
            >
            <Card>
                <FormFilter 
                    handleSearch={performSearch} 
                    handleNew={performNew} 
                    handleClear={performClear} 
                    filterValue={filterValue} 
                    setFilterValue={setFilterValue}            
                />
            </Card>
            <div style={{ marginBottom: '20px' }}></div>
            <Table tableHeadData={tableHeadData} tableData={tableData} handleButtonClick={handleButtonClick} onStatusChange={handleStatusChange} />        
            <Modal 
                width={selectedRowData ? 400 : 800} 
                open={openModal} 
                handleSave={handleSaveModal} 
                handleClose={handleCloseModal} 
                modalTitle="Group" 
            >
                <Autocomplete
                    disablePortal
                    options={userGroupInfo}
                    getOptionLabel={(option) => option.Group}
                    onChange={(event, newValue) => { setSelectedUserGroupInfo(newValue); }}
                    value={selectedUserGroupInfo}                     
                    renderOption={(props, option) => <li {...props} key={option.UserGroupID}>{option.Group}</li>}
                    sx={{ width: '100%', mb: 2 }}
                    renderInput={(params) => (
                        <TextField 
                            {...params} 
                            label="Group"
                            error={Boolean(formErrors.selectedUserGroupInfo)}
                            helperText={formErrors.selectedUserGroupInfo}
                        />
                    )}                    
                    // renderInput={(params) => <TextField {...params} label="Group" />}
                />

                <Autocomplete
                    disablePortal
                    options={userLoginInfo}
                    getOptionLabel={(option) => option.UserLoginName || 'Unknown'}
                    onChange={(event, newValues) => { setSelectedUserLoginInfos(newValues); }}
                    value={selectedUserLoginInfos} 
                    multiple={isMultipleSelectionAllowed}  
                    renderOption={(props, option) => <li {...props} key={option.UserLoginID}>{option.UserLoginName}</li>}
                    sx={{ width: '100%', mb: 2 }}
                    renderInput={(params) => (
                        <TextField 
                            {...params} 
                            label="Logins"
                            error={Boolean(formErrors.selectedUserLoginInfos)}
                            helperText={formErrors.selectedUserLoginInfos}
                        />
                    )}                    
                    // renderInput={(params) => <TextField {...params} label="Logins" />}
                />

                <TextField
                    sx={{ width: '100%', mb: 2 }}
                    label="Effective Date"
                    type="date"
                    value={selectedEffectiveDate}
                    onChange={handleEffectiveDateChange}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    error={Boolean(formErrors.selectedEffectiveDate)}
                    helperText={formErrors.selectedEffectiveDate}                    
                />

                <TextField
                    sx={{ width: '100%', mb: 2 }}
                    label="Expiration Date"
                    type="date"
                    value={selectedExpiryDate}
                    onChange={handleExpiryDateChange}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />                
                      
            </Modal>  
            
            <SnackbarAlert 
                open={snackbarOpen} 
                handleClose={handleSnackbarClose} 
                message={snackbarMessage} 
                severity={snackbarSeverity}
            />              
        </PageLayout>
    );
};

export default GroupMember;
