import React from 'react';
import classes from './ContentHotels.module.css'; 
import close from '../../../assets/image/menu-icons/close-x.svg';
import star from '../../../assets/image/menu-icons/star.svg';
import ContentHotelsData from './ContentHotelsData';

const ContentHotels = () => {
    const facilities = [
        { id: 1, name: "Wifi" },
        { id: 2, name: "Swimming Pool" },
        { id: 3, name: "Parking" },
        { id: 4, name: "Restaurant" },

      ];
    
    const accomodation_preferences = [
        { id: 1, name: "Pay upon Check-in" },
        { id: 2, name: "Family-friendly" },
        { id: 3, name: "Villa" },
        { id: 4, name: "Appartment" },
      ];

    const accomodation_type = [
        { id: 1, name: "B&B" },
        { id: 2, name: "Resorts" },
        { id: 3, name: "Hotels" },
        { id: 4, name: "Villas" },
    ];      

  return (
    <>
    <div className={classes['container-content-hotels']}>
      <aside className={classes.sidebar}>
        <label>Selected</label>

        <div className={classes['selected-holder']}>
            <div className={classes['selected-container']}>
                <div className={classes['selected-value']}>Wifi</div>
                <img src={close} alt="close" />
            </div>
            
            <div className={classes['selected-container']}>
                <div className={classes['selected-value']}>Swimming Pool</div>
                <img src={close} alt="close" />
            </div>

            <div className={classes['selected-container']}>
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={close} alt="close" />
            </div>          
            <div className={classes['selected-container']}>
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={close} alt="close" />
            </div>      
        </div>
        <div className={classes['clear-selection']}>Clear Selection</div>
        <div className={classes['sidebar-divider']}></div>
        <label>Star Rating</label>
        <div className={classes['star-rating-list']}>
            <div className={classes['star-rating']}>
              <input type="checkbox" />
              <img src={star} alt="star" />
            </div>
            <div  className={classes['star-rating']}>
              <input type="checkbox" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div  className={classes['star-rating']}>
              <input type="checkbox" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div  className={classes['star-rating']}>
              <input type="checkbox" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div  className={classes['star-rating']}>
              <input type="checkbox" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>                                                
        </div>  
        <div className={classes['sidebar-divider']}></div>      
        <label>Facilities</label>
        <div className={classes['star-rating-list']}>
            {facilities.map(facility => (
            <div key={facility.id} className={classes['star-rating-item']}>
              <input type="checkbox" id={facility.name + facility.id} />
              <label htmlFor={facility.name + facility.id}>{facility.name}</label>
            </div>
          ))}            
        </div>
        <div className={classes['clear-selection']}>Show All 9</div>
        <div className={classes['sidebar-divider']}></div>      
        <label>Accomodation Preferences</label>        
        <div className={classes['star-rating-list']}>
            {accomodation_preferences.map(item => (
            <div key={item.id} className={classes['star-rating-item']}>
              <input type="checkbox" id={item.name + item.id} />
              <label htmlFor={item.name + item.id}>{item.name}</label>
            </div>
          ))}            
        </div>   
        <div className={classes['clear-selection']}>Show All 5</div>
        <div className={classes['sidebar-divider']}></div>      
        <label>Accomodation Type</label>        
        <div className={classes['star-rating-list']}>
            {accomodation_type.map(item => (
            <div key={item.id} className={classes['star-rating-item']}>
              <input type="checkbox" id={item.name + item.id} />
              <label htmlFor={item.name + item.id}>{item.name}</label>
            </div>
          ))}            
        </div>
        <div className={classes['clear-selection']}>Show All 13</div>              
      </aside>
      <div className={classes['main-content']}>
        <header className={classes.header}>
          <label>3 results found in Bohol</label>

          <div className={classes['holder-price-range-sort-popular']}>
            <div className={classes.selectContainer}>
                <select>
                  <option value="">Price range</option>
                    <option value="place1">1000</option>
                    <option value="place2">2000</option>
                    <option value="place3">3000</option>
                </select>
            </div>    

            <div className={classes['sort-popular-container']}>
                <select>
                  <option value="">Most popular</option>
                    <option value="place1">1000</option>
                    <option value="place2">2000</option>
                    <option value="place3">3000</option>
                </select>
            </div>                    
          </div>
        </header>
        <main className={classes.content}>
          <ContentHotelsData />
        </main>
      </div>
      
    </div>
    
    </>
  );
};

export default ContentHotels;
