export const MODULE_TYPES = [
    { label: 'Select Module Type', value: '' },
    { label: 'Parent', value: 'parent' },
    { label: 'Child', value: 'child' },
    { label: 'Sub Child', value: 'sub-child' },
  ];
  
  export const YES_NO_OPTIONS = [
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
  ];

  export const GROUP_USER_OPTIONS = [
    { label: 'By Group', value: 'group' },
    { label: 'By User', value: 'user' },
  ];
  

  export const IS_UI_COMPONENT_OPTIONS = YES_NO_OPTIONS;
  export const IS_DEFAULT_OPTIONS = YES_NO_OPTIONS;
  