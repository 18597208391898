import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import classes from './TopNavigation.module.css';
import useStore from '../../../store';
import { BASE_URL } from '../../../utils/constants';
import { clearTokenOnLogout, fetchMenus } from '../../../services/storage/indexedDB';

const TopNavigation = ({ onMenuClick }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState(null); 
    const [menus, setMenus] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const initialize = async () => {
            const menusData = await fetchMenus();
            if (menusData) {
                setMenus(menusData);
            }
        };

        initialize();
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleSubMenuClick = (index) => {
        setOpenSubMenu(openSubMenu === index ? null : index); 
    };

    const handleLogout = async () => {
        await clearTokenOnLogout();
        navigate('/login');
    };
    
    return (
        <div className={classes['top-navigation']}>
            <div className={classes.flexContainer}>
                <IconButton onClick={toggleMenu} aria-label="menu" className={classes.menuButton}>  
                    <MenuIcon />
                </IconButton>
                <div className={classes['holder-logo']}>
                    <h2 className={classes['logo-h2']}>TMC<span className={classes['logo-span']}>Back Office</span></h2>
                </div>            
                <IconButton aria-label="logout" className={classes.logoutButton} onClick={handleLogout}>
                    <ExitToAppIcon />
                </IconButton> 
            </div>

            {menuOpen && (
                <nav className={classes.navMenu}>
                    {menus && menus.map((menu, index) => (
                        <React.Fragment key={index}>
                            <a 
                                href={menu.ParentModuleController ? `${BASE_URL}/${menu.ParentModuleController}` : "#"} 
                                className={classes.navItem} 
                                onClick={() => handleSubMenuClick(index)}
                            >
                                {menu.name}
                                {menu.sub_menus && menu.sub_menus.length > 0 && (
                                    openSubMenu === index ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                )}
                            </a>
                            {openSubMenu === index && menu.sub_menus && menu.sub_menus.length > 0 && (
                                <div className={classes.subMenu}>
                                    {menu.sub_menus.map((subMenu, subIndex) => (
                                        <a 
                                            key={subIndex} 
                                            href={subMenu.ChildModuleController ? `${BASE_URL}/${subMenu.ChildModuleController}` : "#"} 
                                            className={classes.subNavItem}
                                        >
                                            {subMenu.name}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </nav>
            )}
        </div>
    );
};

export default TopNavigation;
