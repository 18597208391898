import React from 'react';
import classes from './ContentFlightsData.module.css';
import arrowDown from '../../../assets/image/menu-icons/arrow-down.svg';

const ContentFlightsData = () => {
    return (
        <div className={classes['flight-ticket']}>
            <div className={classes['ticket-header']}>
                <span className={classes['airline-name']}>Philippine Airlines</span>
                <div className={classes['ticket-info']}>
                    <span className={classes['origin-location']}>MNL</span>
                    <span className={classes['departure-time']}>21:45</span>
                    <span className={classes['travel-time']}>2hr 0m</span>
                    <span className={classes['depart-location']}>CEB</span>
                    <span className={classes['arrival-time']}>23:45</span>
                </div>
                <span className={classes['ticket-price']}>₱ 1,752</span>
            </div>
            <div className={classes['ticket-footer']}>
                <label>3 tickets left</label>
                <div className={classes['arrow-down']}>
                    <img src={arrowDown} alt="arrow down" />
                </div>
            </div>
        </div>
    );
};

export default ContentFlightsData;
