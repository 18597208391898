import React,{useState} from 'react';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import classes from './ExploreDestinations.module.css';
import TourByInterest from './TourByInterest';
import PopularDestinations from './PopularDestinations';


const ExploreDestinations = () => {



    return (
        <div className={classes.container}>
            <PublicHeader />
            <div className={classes.bannerSection}>
                <h2 className={classes.mainHeading}>Explore destinations</h2>
            </div>
            <TourByInterest />
            <PopularDestinations />
        
            <PublicFooter />   
        </div>
    );
};

export default ExploreDestinations;

