import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../utils/dateUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import classes from './FilterTourActivities.module.css';
import ContentTourActivities from './ContentTourActivities';
import oceanPark from '../../../assets/image/destinations/ocean-park.jpg';

const locations = ["Cebu", "Palawan", "Bukidnon", "Bohol", "Boracay"];
const destinations = [
    { title: "Manila Ocean Park", price: "₱ 680", imgSrc: oceanPark },
    { title: "Enchanted Kingdom in Laguna", price: "₱ 388", imgSrc: oceanPark },
    { title: "The Mind Museum Ticket", price: "₱ 550", imgSrc: oceanPark },
    { title: "Art in Island: The Media Square", price: "₱ 850", imgSrc: oceanPark },
];
const useOutsideClick = (ref, callback) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, callback]);
};

const FilterTourActivities = () => {
    const [destination, setDestination] = useState('');
    const [fromDate, setFromDate] = useState(formatDate({ date: new Date() }));    
    const [toDate, setToDate] = useState(formatDate({ date: new Date() }));    
    const [placeholder, setPlaceholder] = useState(locations[0]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [index, setIndex] = useState(0);    
    const modalRef = useRef();
    const navigate = useNavigate();

    useOutsideClick(modalRef, () => {
        if (isModalVisible) setIsModalVisible(false);
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
          setIndex((prevIndex) => (prevIndex + 1) % locations.length);
          setPlaceholder(locations[index]);
        }, 3000); 
  
        return () => clearInterval(intervalId);
    }, [index]);

    const handleInputClick = () => {
        setIsModalVisible(true);
    };

    const handleSearch = () => {
        console.log("Searching for:", { destination, fromDate, toDate });
    };

    return (
        <>
            <div className={classes.container}>
                <div className={classes.filterForm}>
                    <div className={classes.selectContainer}>
                        <label>Destination</label>
                        <select value={destination} onChange={(e) => setDestination(e.target.value)}>
                            <option value="">Select Destination</option>
                            <option value="place1">Bohol</option>
                            <option value="place2">Cebu</option>
                            <option value="place3">Palawan</option>
                            <option value="place4">Bukidnon</option>
                        </select>
                    </div>

                    <div className={classes.dateContainer}>
                        <label htmlFor="fromDate">From</label>
                        <input id="fromDate" type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                    </div>

                    <div className={classes.dateContainer}>
                        <label htmlFor="toDate">To</label>
                        <input id="toDate" type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                    </div>

                    <button onClick={handleSearch}>Search</button>
                </div>
            </div>

            {/* search bar for mobile view */}
            <div className={classes['container-search']}>
                <FontAwesomeIcon icon={faSearch} className={classes['search-icon']} />
                <input 
                    key={placeholder} 
                    type="text" 
                    placeholder={placeholder} 
                    className={`${classes['search-input']} ${classes['animate-placeholder']}`} 
                    onClick={handleInputClick} 
                />                
            </div>            
            
            <ContentTourActivities />       

            {isModalVisible && (
                <div ref={modalRef} className={classes.modal}>
                    <div className={classes['container-traveler-choice']}>
                        <div className={classes['container-search-modal']}>
                            <FontAwesomeIcon icon={faSearch} className={classes['search-icon-modal']} />
                            <input 
                                type="text" 
                                className={`${classes['search-input-modal']} `} 
                            />                
                        </div>                           
                        <div className={classes['destination-title']}>Traveler's Choice</div>                        
                        <div className={classes['traveler-destination-choice']}>
                            <span className={classes['location']}>bocaray</span>
                            <span className={classes['location']}>tokyo</span>
                            <span className={classes['location']}>enchanted kingdom</span>
                            <span className={classes['location']}>cebu</span>
                            <span className={classes['location']}>manila</span>
                            <span className={classes['location']}>taipei</span>
                        </div>                        
                        <div className={classes['destination-title']}>Trending Now: <span>Must-Visit Destinations</span></div>
                        <div className={classes['container-card']}>
                            {destinations.map((destination, index) => (
                                <div className={classes['card-item']} onClick={() => navigate('/activity-detail')}>
                                    <img className={classes['item-photo']} src={destination.imgSrc} alt={destination.title} />
                                    <div className={classes['item-content']}>
                                        <div className={classes['item-title']}>{destination.title}</div>
                                        <div className={classes['item-price']}><span>{destination.price}</span></div>
                                    </div>
                                </div>

                            ))}
                        </div>                       
                    </div>
                    
                </div>
            )}            
        </>
    );
};

export default FilterTourActivities;
