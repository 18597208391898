import React,{useState} from 'react';
import classes from './FilterFlights.module.css';
import { formatDate } from '../../../utils/dateUtils';
import ContentFlights from './ContentFlights';

const FilterFlights = () => {
    const [destination, setDestination] = useState('');
    const [fromDate, setFromDate] = useState(formatDate({ date: new Date() }));    
    const [toDate, setToDate] = useState(formatDate({ date: new Date(), daysToAdd: 1 }));

    const handleSearch = () => {
        console.log("Searching for:", { destination, fromDate, toDate });
    };

    return (
        <>
            <div className={classes.container}>
                <div className={classes.filterForm}>
                    <div className={classes.selectContainer}>
                        <label>From</label>
                        <select value={destination} onChange={(e) => setDestination(e.target.value)}>
                            <option value="">Select Origin</option>
                            <option value="place1">Cebu</option>
                            <option value="place2">Manila</option>
                            <option value="place2">Davao</option>
                            <option value="place2">Batanes</option>
                        </select>
                    </div>
                    <div className={classes.selectContainer}>
                        <label>To</label>
                        <select value={destination} onChange={(e) => setDestination(e.target.value)}>
                            <option value="">Select Destination</option>
                            <option value="place1">Cebu</option>
                            <option value="place2">Manila</option>
                            <option value="place2">Davao</option>
                            <option value="place2">Batanes</option>
                        </select>
                    </div>                

                    <div className={classes.dateContainer}>
                        <label htmlFor="fromDate">Depart</label>
                        <input id="fromDate" type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                    </div>

                    <div className={classes.dateContainer}>
                        <label htmlFor="toDate">Return</label>
                        <input id="toDate" type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                    </div>

                    <button onClick={handleSearch}>Search</button>
                </div>
            </div>
            <ContentFlights />
        </>
    );
};

export default FilterFlights;

