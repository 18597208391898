import { postData } from '../services/api';
import { systemId } from './systemHelpers';

export const fetchMenuData = async (excludedMenus = ['Save']) => {
  try {
    const response = await systemId();
    const result = await postData('navigation', { ModuleParentID: 0, SystemID: response.system_id });

    // Filter out items with names in the excludedMenus array
    const filteredResult = result.filter(item => !excludedMenus.includes(item.name));        

    const menuArray = Object.values(filteredResult);

    return menuArray
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};