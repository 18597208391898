import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faEdit, faRedo   } from '@fortawesome/free-solid-svg-icons';
import classes from './TourAndActivity.module.css';

const TourAndActivity = () => {
  const tours = [
    { id: 1, title: "Romantic Paris", description: "A week in Paris", duration: "7 days", destination: "Paris", status: "Active" },
    { id: 2, title: "Adventure in New Zealand", description: "Exploring the South Island", duration: "10 days", destination: "New Zealand", status: "Active" },
    { id: 3, title: "Cultural Japan", description: "Historical landmarks and modern cities", duration: "12 days", destination: "Japan", status: "Active" }
  ];

  return (
        <div className={classes['container-tour-activity']}>
          <div className={classes['container-form-filter']}>
            <input className={classes['search-input']} placeholder='please enter to search'/>
            <button className={classes['btn-search']}>
              <FontAwesomeIcon icon={faSearch} /> <span>Search</span>
            </button>
            <button className={classes['btn-new']}>
              <FontAwesomeIcon icon={faPlus} /> <span>New</span>
            </button>
            <button className={classes['btn-clear']}>
              <FontAwesomeIcon icon={faRedo } /> <span>Clear</span>
            </button>            
          </div>
          <div className={classes['container-data']}>
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Duration</th>
                  <th>Destination</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tours.map(tour => (
                  <tr key={tour.id}>
                    <td data-label="Title">{tour.title}</td>
                    <td data-label="Desc">{tour.description}</td>
                    <td data-label="Duration">{tour.duration}</td>
                    <td data-label="Destination">{tour.destination}</td>
                    <td data-label="Status">{tour.status}</td>
                    <td data-label="ACtion">
                      <button className={classes['btn-edit']}>
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
  );
};

export default TourAndActivity;
