import React, { useState, useEffect } from 'react';
import {PageLayout} from '../components/layout/PageLayout/PageLayout';
import Card from '../components/common/elements/Card/Card';
import Table from '../components/common/elements/Table/Table';
import FormFilter from '../components/common/elements/FormFilter/FormFilter';
import Modal from '../components/common/elements/Modal/Modal';
import TextField from '@mui/material/TextField';
import { fetchData, postData } from '../services/api';
import SnackbarAlert from '../components/common/elements/SnackbarAlert/SnackbarAlert';


const Entity = () => {
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [filterValue, setFilterValue] = useState(''); 
    const [entityName, setEntityName] = useState('');
    const [entityNameError, setEntityNameError] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // or "error", "info", "warning"

    const tableHeadData = ['Name', 'Code', 'Status', 'Action'];

    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };

    const handleStatusChange = async ({ newStatus, rowIndex, row }) => {
      const originalData = [...tableData]; 
      const updatedData = [...tableData];
      updatedData[rowIndex].status = newStatus;
    
      const payload = { 
        EntityName: row.name, 
        ReferenceTableStatusID: newStatus === 'Active' ? 1 : 2 
      };
    
      try {
        await postData('entitynames/update', { ...payload, EntityNameID: row.id }); 
        setTableData(updatedData);
      } catch (error) {
        console.error("Failed to update:", error);
        setTableData(originalData);
      }
    };    

    const handleButtonClick = (rowId) => {
      const row = tableData.find((data) => data.id === rowId);
      if (row) {
        setSelectedRowData(row);
        setEntityName(row.name); 
        setOpenModal(true);
      }
    };    

    const performSearch = async () => {
      await fetchData('entitynames', { filter: filterValue, momo:123 })
      .then((result) => {
          if (result.code === 404) {
            setTableData([]); 
            setSnackbarMessage(result.message);
            setSnackbarOpen(true); 
          }else{
            const resultArray = Object.values(result.data).map((item) => ({
              id: item.EntityNameID,
              name: item.EntityName,
              code: item.EntityNameCode,
              status: item.EntityStatus,
            }));
            setTableData(resultArray);
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
    };      
    
    const performNew = () => {
      setEntityName('');
      setEntityNameError(false);
      setSelectedRowData(null); 
      setOpenModal(true);
    };
    
    const performClear = () => {
      setTableData([]); 
      setFilterValue(''); 
    };  
  
    const handleSaveModal = async () => {
      setEntityNameError(!entityName);
    
      if (!entityName) return;
    
      try {
        const payload = { EntityName: entityName };
        const endpoint = selectedRowData ? 'entitynames/update' : 'entitynames';
    
        const { data } = await postData(endpoint, {
          ...payload,
          EntityNameID: selectedRowData?.id
        });
    
        if (data.length > 0) {
          const { EntityNameID: id, EntityNameCode: code, EntityName: name, EntityStatus: status } = data[0];
          const newEntry = { id, name, code, status };
    
          setTableData((prevTableData) => {
            if (selectedRowData) {
              return prevTableData.map((item) => (item.id === selectedRowData.id ? newEntry : item));
            } else {
              return [...prevTableData, newEntry];
            }
          });
    
          setOpenModal(false);

          const action = selectedRowData ? 'updated' : 'added';
          setSnackbarMessage(`Record successfully ${action}.`);
          setSnackbarSeverity('success');            
          setSnackbarOpen(true);          
        }
      } catch (error) {
        console.error('Error saving:', error);
      }
    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    return (
      <PageLayout
        sm={3}
        itemLeftPadding={2}
        alignItems="flex-start"
        breadcrumbs={[
          { label: "Home", href: "/" },
          { label: "Setup", href: "#" },
          { label: "Entity", href: "#" }
        ]}
      >
        <Card>
          <FormFilter 
            handleSearch={performSearch} 
            handleNew={performNew} 
            handleClear={performClear} 
            filterValue={filterValue} 
            setFilterValue={setFilterValue}            
          />
        </Card>
        <div style={{ marginBottom: '20px' }}></div>
        <Table tableHeadData={tableHeadData} tableData={tableData} handleButtonClick={handleButtonClick} onStatusChange={handleStatusChange} />
        <Modal 
          width={400} 
          open={openModal} 
          handleSave={handleSaveModal} 
          handleClose={handleCloseModal} 
          modalTitle="Entity" 
        >
          <TextField
              fullWidth
              label="Entity Name"
              type="search"
              placeholder="Enter name here"
              sx={{ mb: 2 }}
              required
              error={entityNameError}
              helperText={entityNameError ? 'EntityName is required' : ''}
              value={entityName}
              onChange={e => setEntityName(e.target.value)}                    
          /> 
        </Modal>
        <SnackbarAlert 
            open={snackbarOpen} 
            handleClose={handleSnackbarClose} 
            message={snackbarMessage} 
            severity={snackbarSeverity}
        />

      </PageLayout>
    );
};

export default Entity;
