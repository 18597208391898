import React, {useState} from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import { Carousel } from 'react-responsive-carousel';
import classes from './TourActivityDetail.module.css'; 
import oceanPark from '../../../assets/image/destinations/ocean-park.jpg';
import oceanPark2 from '../../../assets/image/destinations/ocean-park2.png';
import oceanPark3 from '../../../assets/image/destinations/ocean-park3.jpg';
import oceanPark4 from '../../../assets/image/destinations/ocean-park4.jpg';
import PublicHeader from '../../../components/layout/Header/PublicHeader';
import PublicFooter from '../../../components/layout/Footer/PublicFooter';
import star from '../../../assets/image/menu-icons/star.svg'


const TourActivityDetail = () => {
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);

    const openGallery = () => {
      setIsGalleryOpen(true);
    };
  
    const closeGallery = () => {
      setIsGalleryOpen(false);
    };
    
    const getStarRating = (bookingRate) => {
        if (bookingRate < 1000) return 1;
        if (bookingRate >= 1000 && bookingRate < 1500) return 2;
        if (bookingRate >= 1500 && bookingRate < 2000) return 3;
        if (bookingRate >= 2000 && bookingRate < 2500) return 4;
        if (bookingRate >= 2500) return 5;
        return 0; 
    };    

    return (
        <>
        <PublicHeader />
        <div className={classes['container-tour-activity-detail']}>
            <h1 className={classes['title']}>Fort Santiago Ticket in Intramuros Manila</h1>
            <span className={classes['badge']}>Bestseller</span>

            <p>
                {Array(getStarRating(2500)).fill().map((_, idx) => (
                    <img key={idx} className={classes.star} src={star} alt="star" />
                ))}                  
                <span className={classes.bookedCount}>
                   <span>(100 reviews)</span> • 100++ booked 
                </span>                            
            </p>

            <div className={classes['container-photo']}>
                <img className={classes['photo']} src={oceanPark} alt="Main View of Ocean Park" />
                <div className={classes['photo-stack']}>
                    <img className={classes['photo-small']} src={oceanPark2} alt="Additional View of Ocean Park" />
                    <img className={classes['photo-small']} src={oceanPark3} alt="Another View of Ocean Park" />
                </div>
                <span className={classes['gallery-icon']} onClick={openGallery}>More Photos</span>
            </div>

            {isGalleryOpen && (
          <div className={classes['carousel-overlay']}>
            <div className={classes['carousel-container']}>
              <button className={classes['close-button']} onClick={closeGallery}>Close</button>
              <Carousel>
                <div>
                  <img src={oceanPark} alt="Gallery Image 1" />
                </div>
                <div>
                  <img src={oceanPark2} alt="Gallery Image 2" />
                </div>
                <div>
                  <img src={oceanPark3} alt="Gallery Image 3" />
                </div>
                <div>
                  <img src={oceanPark4} alt="Gallery Image 3" />
                </div>
              </Carousel>
            </div>
          </div>
        )}

            
                        
        </div>
        <PublicFooter />
        </>
    );
};

export default TourActivityDetail;
