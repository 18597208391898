import React,{useState} from 'react';
import classes from './TourByInterest.module.css';

import tour1 from '../../assets/image/tours-by-interest/tour1@3x.png';
import tour2 from '../../assets/image/tours-by-interest/tour2@3x.png';
import tour3 from '../../assets/image/tours-by-interest/tour3@3x.png';
import tour4 from '../../assets/image/tours-by-interest/tour4@3x.png';
import tour5 from '../../assets/image/tours-by-interest/tour5@3x.png';
import tour6 from '../../assets/image/tours-by-interest/tour6@3x.png';

const toursByInterest = [
    { image: tour1, title: 'Adventure' },
    { image: tour2, title: 'Cultural' },
    { image: tour3, title: 'Marine' },
    { image: tour4, title: 'Wildlife' },
    { image: tour5, title: 'Hiking' },
    { image: tour6, title: 'Spa & Massage' },
  ];

const TourByInterest = (props) => {
    


    return (
        <div className={classes.tourByInterestSection}>
            <h2 className={classes.tourByInterestTitle}>{props.title}</h2>
            <div className={classes.tourList}>
            {toursByInterest.map((tour, index) => (
                <div key={index} className={classes.tourCard}>
                <img src={tour.image} alt={tour.title} className={classes.tourImage} />
                <div className={classes.tourTitle}>{tour.title}</div>
                </div>
            ))}
            </div>
        </div>
    );
};

export default TourByInterest;

