import React from 'react';
import classes from './ContentFlights.module.css'; 
import close from '../../../assets/image/menu-icons/close-x.svg';
import ContentFlightsPriceSlide from './ContentFlightsPriceSlide';
import ContentFlightsData from './ContentFlightsData';


const ContentFlights = () => {
    const airlines = [
        { id: 1, name: "Air Philippines" },
        { id: 2, name: "Cebu Pacific Air" },
        { id: 3, name: "Parking" },
        { id: 4, name: "Philippine Airline" },
        { id: 5, name: "Air Asia" },
        { id: 6, name: "Lufthansa Airline" },
    ];

    const departure_airports = [
        { id: 1, name: "Ninoy Aquino International Airport(MNL)" },
    ];

    const arrival_airports = [
        { id: 1, name: "Mactan Cebu International Airport(CEB)" },
    ];    

    return (
    <>
        <div className={classes['container-content-flights']}>
            <aside className={classes.sidebar}>
                <label>Selected</label>

                <div className={classes['selected-holder']}>
                    <div className={classes['selected-container']}>
                        <div className={classes['selected-value']}>Air Philippines</div>
                        <img src={close} alt="close" />
                    </div>
                    
                    <div className={classes['selected-container']}>
                        <div className={classes['selected-value']}>Cebu Pacific Air</div>
                        <img src={close} alt="close" />
                    </div>
                </div>
                <div className={classes['clear-selection']}>Clear Selection</div>

                <div className={classes['sidebar-divider']}></div>  

                <label>Airlines</label>
                <div className={classes['category-list']}>
                    {airlines.map(airline => (
                    <div key={airline.id} className={classes['category-item']}>
                    <input type="checkbox" id={airline.name + airline.id} />
                    <label htmlFor={airline.name + airline.id}>{airline.name}</label>
                    </div>
                ))}            
                </div>
                <div className={classes['clear-selection']}>Show All 9</div>

                <div className={classes['sidebar-divider']}></div>      

                <label>Departure Airports</label>        
                <div className={classes['category-list']}>
                    {departure_airports.map(airport => (
                    <div key={airport.id} className={classes['category-item']}>
                    <input type="checkbox" id={airport.name + airport.id} />
                    <label htmlFor={airport.name + airport.id}>{airport.name}</label>
                    </div>
                ))}            
                </div>   
                <div className={classes['clear-selection']}>Show All 5</div>

                <div className={classes['sidebar-divider']}></div> 

                <label>Arrival Airports</label>        
                <div className={classes['category-list']}>
                    {arrival_airports.map(airport => (
                    <div key={airport.id} className={classes['category-item']}>
                    <input type="checkbox" id={airport.name + airport.id} />
                    <label htmlFor={airport.name + airport.id}>{airport.name}</label>
                    </div>
                ))}            
                </div>
                <div className={classes['clear-selection']}>Show All 13</div>              
            </aside>
            <div className={classes['main-content']}>
                <header className={classes.header}>
                <label>3 results found in Manila</label>

                <div className={classes['holder-price-range-sort-popular']}>
                    <div className={classes.selectContainer}>
                        <select>
                        <option value="">Price range</option>
                            <option value="place1">1000</option>
                            <option value="place2">2000</option>
                            <option value="place3">3000</option>
                        </select>
                    </div>    

                    <div className={classes['sort-popular-container']}>
                        <select>
                        <option value="">Most popular</option>
                            <option value="place1">1000</option>
                            <option value="place2">2000</option>
                            <option value="place3">3000</option>
                        </select>
                    </div>                    
                </div>
                </header>
                <main className={classes.content}>
                    <ContentFlightsPriceSlide />
                    <ContentFlightsData />
                    <ContentFlightsData />
                    <ContentFlightsData />
                </main>
            </div>
        
        </div>
    
    </>
    );
};

export default ContentFlights;