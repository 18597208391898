import React from 'react';

export const TravelLogo = ({ className }) => (
    <svg className={className} viewBox="0 0 512 512" version="1.1" id="Layer_1" >
    <path d="M484.304,236.878c-4.094-4.094-8.842-7.296-14-9.53C469.999,101.936,367.881,0,242.397,0
        c-19.163,0-46.522,12.488-68.038,71.983c-7.473,20.662-13.282,44.623-17.256,70.638c-26.015,3.974-49.976,9.782-70.638,17.256
        c-15.249,5.514-27.391,11.414-37.045,17.46c8.826-33.91,26.529-65.004,51.962-90.437c5.564-5.563,5.564-14.582,0-20.145
        c-5.561-5.563-14.582-5.563-20.145,0c-43.047,43.047-66.755,100.283-66.755,161.16c0,55.382,20.106,108.774,56.614,150.341
        c36.167,41.18,85.84,67.964,139.866,75.423c0.214,0.03,0.557,0.033,0.758,0.188l44.56,53.05c2.568,3.057,6.3,4.897,10.29,5.07
        c0.207,0.009,0.413,0.013,0.618,0.013c3.769,0,7.394-1.496,10.072-4.172l91.555-91.555l5.446,58.09
        c0.516,5.496,4.159,10.199,9.352,12.071c5.194,1.872,11,0.574,14.903-3.328l29.045-29.045c2.218-2.219,3.642-5.108,4.051-8.219
        l13.951-106.318l38.741-38.741C501.922,283.163,501.924,254.497,484.304,236.878z M274.399,289.774
        c-0.366,0.048-0.728,0.113-1.087,0.188c-9.87,0.62-20.213,0.984-30.914,0.984c-21.421,0-41.405-1.105-59.813-3.067
        c-2.095-19.182-3.219-39.375-3.219-59.966c0-21.475,1.111-41.505,3.083-59.95c18.445-1.971,38.476-3.083,59.95-3.083
        s41.505,1.111,59.95,3.083c1.97,18.445,3.083,38.476,3.083,59.95c0,7.867,6.377,14.245,14.245,14.245s14.245-6.377,14.245-14.245
        c0-19.126-0.902-37.889-2.62-55.96c66.47,11.347,106.335,34.251,110.201,52.997c-7.919,1.944-15.185,6.013-21.099,11.926
        l-38.83,38.831L274.399,289.774z M222.57,396.152l-7.714,7.714c-12.265-20.27-22.068-50.943-28.234-87.029
        c17.441,1.652,35.529,2.531,53.962,2.585l-3.449,3.449c-3.903,3.903-5.199,9.711-3.328,14.903c1.872,5.194,6.575,8.837,12.071,9.352
        l58.769,5.51l-50.017,50.016l-14.753-8.698C234.28,390.655,227.162,391.561,222.57,396.152z M435.306,177.292
        c-9.642-6.03-21.764-11.914-36.975-17.416c-20.662-7.473-44.623-13.28-70.638-17.256c-3.974-26.015-9.782-49.976-17.255-70.638
        c-5.501-15.21-11.386-27.333-17.416-36.975C362.376,53.221,417.091,107.936,435.306,177.292z M242.397,28.489
        c19.232,0,44.008,40.51,55.96,110.523c-18.071-1.719-36.832-2.62-55.96-2.62s-37.889,0.902-55.96,2.62
        C198.391,69,223.166,28.489,242.397,28.489z M153.496,171.955c-1.719,18.071-2.62,36.833-2.62,55.96
        c0,19.163,0.922,37.936,2.664,55.967c-70.041-11.95-110.567-36.731-110.567-55.967C42.971,208.683,83.481,183.908,153.496,171.955z
        M49.573,278.592c9.629,6.011,21.726,11.877,36.892,17.363c20.69,7.483,44.686,13.297,70.741,17.272
        c6.852,44.433,18.958,82.435,34.757,107.674C122.095,402.643,67.787,347.336,49.573,278.592z M464.16,280.636l-42.163,42.164
        c-2.218,2.218-3.642,5.107-4.051,8.219l-13.951,106.318l-4.197,4.197l-5.446-58.09c-0.516-5.496-4.159-10.199-9.352-12.071
        c-5.194-1.873-11.001-0.575-14.903,3.328L268.103,476.697l-39.162-46.623l5.974-5.974l14.753,8.698
        c5.594,3.299,12.712,2.393,17.306-2.198l79.245-79.245c3.903-3.903,5.199-9.711,3.328-14.903s-6.575-8.837-12.071-9.352
        l-58.769-5.51l3.704-3.704c22.524-1.715,45.268-4.833,67.265-9.255l40.402-5.302c3.111-0.409,6-1.832,8.219-4.051l42.251-42.253
        c3.154-3.154,7.347-4.89,11.807-4.89s8.654,1.736,11.806,4.89C470.669,263.534,470.669,274.126,464.16,280.636z"/>
    </svg>    
);

export const HotelLogo = ({ className }) => (
    <svg className={className} version="1.1" id="Layer_1" viewBox="0 0 295.238 295.238">
        <g>
            <g>
                <polygon  points="0,295.238 71.429,295.238 71.429,285.714 9.524,285.714 9.524,128.571 71.429,128.571 
                    71.429,119.048 0,119.048 		"/>
                <polygon points="223.809,119.048 223.809,128.571 285.714,128.571 285.714,285.714 223.809,285.714 
                    223.809,295.238 295.238,295.238 295.238,119.048 		"/>
                <path d="M52.381,142.857H23.81v28.571h28.571C52.381,171.428,52.381,142.857,52.381,142.857z
                    M42.857,161.905h-9.524v-9.524h9.524C42.857,152.381,42.857,161.905,42.857,161.905z"/>
                <path d="M52.381,185.714H23.81v28.571h28.571C52.381,214.285,52.381,185.714,52.381,185.714z
                    M42.857,204.762h-9.524v-9.524h9.524C42.857,195.238,42.857,204.762,42.857,204.762z"/>
                <path d="M23.81,257.143h28.571v-28.571H23.81V257.143z M33.333,238.095h9.524v9.524h-9.524V238.095z"/>
                <path d="M271.428,142.857h-28.571v28.571h28.571V142.857z M261.905,161.905h-9.524v-9.524h9.524V161.905z"
                    />
                <path d="M242.857,214.286h28.571v-28.571h-28.571L242.857,214.286L242.857,214.286z M252.381,195.238h9.524
                    v9.524h-9.524V195.238z"/>
                <path d="M242.857,257.143h28.571v-28.571h-28.571L242.857,257.143L242.857,257.143z M252.381,238.095h9.524
                    v9.524h-9.524V238.095z"/>
                <path d="M228.571,295.238H66.667V38.095h161.905L228.571,295.238L228.571,295.238z"/>
                <polygon points="223.809,9.524 223.809,0 214.286,0 209.524,0 85.714,0 80.952,0 71.429,0 71.429,9.524 
                    80.952,9.524 80.952,42.857 90.476,42.857 90.476,9.524 204.762,9.524 204.762,42.857 214.286,42.857 214.286,9.524 		"/>
                <path d="M119.048,61.905H90.476v28.571h28.571L119.048,61.905L119.048,61.905z M109.524,80.952H100v-9.524
                    h9.524V80.952z"/>
                <path d="M161.905,61.905h-28.571v28.571h28.571V61.905z M152.381,80.952h-9.524v-9.524h9.524V80.952z"/>
                <path d="M204.762,61.905H176.19v28.571h28.571L204.762,61.905L204.762,61.905z M195.238,80.952h-9.524
                    v-9.524h9.524V80.952z"/>
                <path d="M195.238,209.524H100h-4.762h-9.524v9.524h9.524v76.19H200v-76.19h9.524v-9.524H200H195.238z
                    M104.762,219.048h38.095v19.048h-4.762c-7.876,0-14.286,6.41-14.286,14.286s6.41,14.286,14.286,14.286h4.762v19.048h-38.095
                    C104.762,285.716,104.762,219.048,104.762,219.048z M142.857,257.143h-4.762c-2.624,0-4.762-2.133-4.762-4.762
                    s2.138-4.762,4.762-4.762h4.762V257.143z M152.381,247.619h4.762c2.624,0,4.762,2.133,4.762,4.762s-2.138,4.762-4.762,4.762
                    h-4.762V247.619z M190.476,285.714h-38.095v-19.048h4.762c7.876,0,14.286-6.41,14.286-14.286s-6.41-14.286-14.286-14.286h-4.762
                    v-19.048h38.095V285.714z"/>
                <path d="M90.476,133.333h28.571v-28.571H90.476V133.333z M100,114.286h9.524v9.524H100V114.286z"/>
                <path d="M133.333,133.333h28.571v-28.571h-28.571V133.333z M142.857,114.286h9.524v9.524h-9.524V114.286z"
                    />
                <path d="M176.19,133.333h28.571v-28.571H176.19V133.333z M185.714,114.286h9.524v9.524h-9.524V114.286z"/>
                <path d="M90.476,176.19h28.571v-28.571H90.476V176.19z M100,157.143h9.524v9.524H100V157.143z"/>
                <path d="M133.333,176.19h28.571v-28.571h-28.571V176.19z M142.857,157.143h9.524v9.524h-9.524V157.143z"/>
                <path d="M176.19,176.19h28.571v-28.571H176.19V176.19z M185.714,157.143h9.524v9.524h-9.524V157.143z"/>
                <rect x="85.714" y="190.476"  width="104.762" height="9.524"/>
                <rect x="200" y="190.476"  width="9.524" height="9.524"/>
            </g>
        </g>
    </svg>  
);


export const PlaneLogo = ({ className }) => (
    <svg className={className} version="1.1" id="Layer_1" viewBox="0 0 122.88 122.88" >
        <path d="M16.63,105.75c0.01-4.03,2.3-7.97,6.03-12.38L1.09,79.73c-1.36-0.59-1.33-1.42-0.54-2.4l4.57-3.9
            c0.83-0.51,1.71-0.73,2.66-0.47l26.62,4.5l22.18-24.02L4.8,18.41c-1.31-0.77-1.42-1.64-0.07-2.65l7.47-5.96l67.5,18.97L99.64,7.45
            c6.69-5.79,13.19-8.38,18.18-7.15c2.75,0.68,3.72,1.5,4.57,4.08c1.65,5.06-0.91,11.86-6.96,18.86L94.11,43.18l18.97,67.5
            l-5.96,7.47c-1.01,1.34-1.88,1.23-2.65-0.07L69.43,66.31L45.41,88.48l4.5,26.62c0.26,0.94,0.05,1.82-0.47,2.66l-3.9,4.57
            c-0.97,0.79-1.81,0.82-2.4-0.54l-13.64-21.57c-4.43,3.74-8.37,6.03-12.42,6.03C16.71,106.24,16.63,106.11,16.63,105.75
            L16.63,105.75z"/>
    </svg>   
);