import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import { cloneDeep } from 'lodash';  // Importing cloneDeep from lodash
import getIconComponent from '../../../../utils/iconUtils';
import classes from './Table.module.css';
import CustomSwitch from '../Button/CustomSwitch'; 
import Checkbox from '@mui/material/Checkbox';
import DynamicButton from '../Button/DynamicButton';


const CustomTable = (props) => {
  const { minWidth = 650, tableHeadData = [], tableData = [], defaultRowsPerPage = 10, showCheckbox = false, showDynamicButton = true } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableDataState, setTableDataState] = useState(cloneDeep(tableData));
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState(tableData.map(() => false));

  const label = { inputProps: { 'aria-label': 'Check All' } };
  
  useEffect(() => {
    setCheckboxes(tableData.map(() => false));
  }, [tableData]);
  
  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const newCheckboxes = checkboxes.map(() => newSelectAll);
    setCheckboxes(newCheckboxes);
    
    // If you want to propagate this change to the parent component
    if (props.onSelectAllChange) {
        props.onSelectAllChange(newSelectAll);
    }
};

  
  const handleIndividualCheckboxChange = (index) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setCheckboxes(newCheckboxes);
  
    const areAllChecked = newCheckboxes.every(val => val);
    const areNoneChecked = newCheckboxes.every(val => !val);
    if (areAllChecked) {
      setSelectAll(true);
    } else if (areNoneChecked) {
      setSelectAll(false);
    } else {
      setSelectAll(false);
    }
  };
 
    
  const handleStatusChange = (event, rowIndex) => { 
    if (props.onStatusChange) {
      props.onStatusChange({
        newStatus: event.target.checked ? 'Active' : 'Inactive',
        rowIndex,
        row: tableDataState[rowIndex]
      });
    }
  };

  const handleButtonClick = (rowId) => {
    if (typeof rowId !== 'undefined' && rowId !== null) { // Check if rowId is not null
      props.handleButtonClick(rowId);  // Pass it back to parent
    }
  };  

  const handleRowClick = (rowIndex) => {
    setSelectedRow(rowIndex);
  };

  useEffect(() => {
    setTableDataState(cloneDeep(tableData));  // Synchronize local state with incoming prop
  }, [tableData]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth }} aria-label="simple table">
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {showCheckbox && 
                <TableCell sx={{ width: '10%' }}>
                    <Checkbox 
                        {...label} 
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} 
                        // checked={checkboxes.every(val => val)} 
                        checked={checkboxes.length > 0 && checkboxes.every(val => val)}
                        onChange={handleSelectAllChange} 
                    />                                      
                </TableCell>}
            {tableHeadData.map((data, index) => (
                <TableCell sx={{ fontWeight: 'bold', color: 'white', width: '150px' }} key={index}>
                    {data}
                </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableDataState.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              onClick={(e) => { e.stopPropagation(); handleRowClick(rowIndex); }}
              sx={{ '&.Mui-selected': { backgroundColor: 'lightgray' } }}
              selected={selectedRow === rowIndex}
            >
              {showCheckbox && 
                <TableCell sx={{ width: '10%' }}>
                    {/* <Checkbox {...label} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} /> */}
                    <Checkbox 
                        {...label} 
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} 
                        checked={props.checkboxes[rowIndex]} 
                        onChange={() => props.onCheckboxChange(rowIndex, !checkboxes[rowIndex])}
                    />               
                </TableCell>
              }

              {Object.keys(row).map((key, cellIndex) => {
                if (key === 'status') {
                  return (
                    <TableCell key={cellIndex}>
                      <CustomSwitch
                        checked={row[key] === 'Active'}
                        onChange={(event) => { event.stopPropagation(); handleStatusChange(event, rowIndex); }}
                        name="status"
                        inputProps={{ 'aria-label': 'controlled' }}
                      />                      
                    </TableCell>
                  );
                }
                if (key !== 'id') {
                  return (
                    <TableCell key={cellIndex}>
                      {row[key]}
                    </TableCell>
                  );
                }
                return null;
              })}

              {showDynamicButton &&
                <TableCell key={rowIndex}>
                  <DynamicButton 
                      config={{
                          icon: 'mode-edit',
                          text: 'Edit',
                          condition: row.status !== 'Active',
                          classNameDisabled: classes.buttonDisabled,
                          classNameEnabled: classes.buttonEnabled,
                          handleClick: () => handleButtonClick(row.id),
                      }} 
                      row={row}
                  />
                </TableCell>              
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tableDataState.length}  
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => {
          setPage(newPage);
        }}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </TableContainer>
  );
};

export default CustomTable;



// import React, { useState, useEffect } from 'react';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import Button from '@mui/material/Button';
// import TablePagination from '@mui/material/TablePagination';
// import { cloneDeep } from 'lodash';  // Importing cloneDeep from lodash
// import getIconComponent from '../../../../utils/iconUtils';
// import classes from './Table.module.css';
// import CustomSwitch from '../Button/CustomSwitch'; 
// import Checkbox from '@mui/material/Checkbox';


// const CustomTable = (props) => {
//   const { minWidth = 650, tableHeadData = [], tableData = [], defaultRowsPerPage = 10, showCheckbox = false } = props;
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
//   const [selectedRow, setSelectedRow] = useState(null);
//   const [tableDataState, setTableDataState] = useState(cloneDeep(tableData));  // Added this line

//   const label = { inputProps: { 'aria-label': 'Check All' } };

//   const handleStatusChange = (event, rowIndex) => { 
//     if (props.onStatusChange) {
//       props.onStatusChange({
//         newStatus: event.target.checked ? 'Active' : 'Inactive',
//         rowIndex,
//         row: tableDataState[rowIndex]
//       });
//     }
//   };

//   const handleButtonClick = (rowId) => {
//     if (typeof rowId !== 'undefined' && rowId !== null) { // Check if rowId is not null
//       props.handleButtonClick(rowId);  // Pass it back to parent
//     }
//   };  

//   const handleRowClick = (rowIndex) => {
//     setSelectedRow(rowIndex);
//   };

//   useEffect(() => {
//     setTableDataState(cloneDeep(tableData));  // Synchronize local state with incoming prop
//   }, [tableData]);

//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ minWidth }} aria-label="simple table">
//         <TableHead className={classes.tableHeader}>
//           <TableRow>
//             {showCheckbox && 
//                 <TableCell sx={{ width: '10%' }}>
//                     <Checkbox {...label} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
//                 </TableCell>}
//             {tableHeadData.map((data, index) => (
//                 <TableCell sx={{ fontWeight: 'bold', color: 'white', width: '150px' }} key={index}>
//                     {data}
//                 </TableCell>
//             ))}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {tableDataState.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
//             <TableRow
//               key={rowIndex}
//               onClick={(e) => { e.stopPropagation(); handleRowClick(rowIndex); }}
//               sx={{ '&.Mui-selected': { backgroundColor: 'lightgray' } }}
//               selected={selectedRow === rowIndex}
//             >
//               {Object.keys(row).map((key, cellIndex) => {
//                 if (key === 'status') {
//                   return (
//                     <TableCell key={cellIndex}>
//                       <CustomSwitch
//                         checked={row[key] === 'Active'}
//                         onChange={(event) => { event.stopPropagation(); handleStatusChange(event, rowIndex); }}
//                         name="status"
//                         inputProps={{ 'aria-label': 'controlled' }}
//                       />                      
//                     </TableCell>
//                   );
//                 }
//                 if (key !== 'id') {
//                   return (
//                     <TableCell key={cellIndex}>
//                       {row[key]}
//                     </TableCell>
//                   );
//                 }
//                 return null;
//               })}
//               <TableCell key={rowIndex}>
//                 <Button
//                   startIcon={getIconComponent('mode-edit')}
//                   variant="contained"
//                   onClick={() => handleButtonClick(row.id)}
//                   className={`customButton ${row.status !== 'Active' ? classes.buttonDisabled : classes.buttonEnabled}`}
//                   disabled={row.status !== 'Active'}
//                 >
//                   Edit
//                 </Button>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//       <TablePagination
//         rowsPerPageOptions={[5, 10, 25]}
//         component="div"
//         count={tableDataState.length}  
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={(event, newPage) => {
//           setPage(newPage);
//         }}
//         onRowsPerPageChange={(event) => {
//           setRowsPerPage(parseInt(event.target.value, 10));
//           setPage(0);
//         }}
//       />
//     </TableContainer>
//   );
// };

// export default CustomTable;