import React,{useState} from 'react';
import classes from './PopularDestinations.module.css';


import top from '../../assets/image/popular-destinations/top@2x.png';
import top1 from '../../assets/image/popular-destinations/top1@2x.png';
import top2 from '../../assets/image/popular-destinations/top2@2x.png';
import top3 from '../../assets/image/popular-destinations/top3@2x.png';


const popularDestinations = [
    {
      image: top,
      location: 'Alegria, Cebu',
      activity: 'Canyoneering',
      place: 'Kawasan Falls',
      destination_rate: '1k++ booked',
      price: '5000',
      badge: 'Top Seller',
      booked_count: 500
    },
    {
      image: top1,
      location: 'Puerto Princesa City',
      activity: 'Piers & Boardwalks',
      place: 'Baywalk Parks',
      destination_rate: '1k++ booked',
      price: '10000',
      badge: 'Special Offer',
      booked_count: 1500
    },
    {
      image: top2,
      location: 'Cebu City, Cebu',
      activity: 'Museum',
      place: 'Fort San Pedro',
      destination_rate: '1k++ booked',
      price: '15000',
      badge: 'Top Seller',
      booked_count: 2100
    },
    {
      image: top3,
      location: 'Albay',
      activity: 'Historical landmark',
      place: 'Cagsawa Ruins',
      destination_rate: '1k++ booked',
      price: '25000',
      badge: 'Special Offer',
      booked_count: 5000
    },    
  ];
  
  const getStarRating = (bookingRate) => {
    if (bookingRate < 1000) return 1;
    if (bookingRate >= 1000 && bookingRate < 1500) return 2;
    if (bookingRate >= 1500 && bookingRate < 2000) return 3;
    if (bookingRate >= 2000 && bookingRate < 2500) return 4;
    if (bookingRate >= 2500) return 5;
    return 0; // Default return in case of an unexpected value
  };
  

const PopularDestinations = (props) => {

    return (
        <div className={classes.popularDestinationSection}>
            <h2 className={classes.popularDestinationTitle}>Popular Destinations</h2>
            <div className={classes.destinationList}>
            {popularDestinations.map((dest, index) => (
                <div key={index} className={classes.destinationCard}>
                <img src={dest.image} alt={dest.place} className={classes.destinationImage} />
                <div className={classes.destinationInfo}>
                    <p>{dest.activity} • {dest.location}</p>
                    <p>{dest.place}</p>
                    <p>
                    {Array(getStarRating(dest.booked_count)).fill().map((_, idx) => (
                        <span key={idx} className={classes.star}>⭐</span> 
                    ))}                  
                    {dest.booked_count}++ booked
                    </p>
                    {dest.badge && <span className={classes.badge}>{dest.badge}</span>}
                    <p>Price: ₱{dest.price}</p>
                </div>
                </div>
            ))}
            </div>
            <button className={classes.seeMoreButton}>See More</button>
        </div>
    );
};

export default PopularDestinations;

