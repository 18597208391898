import React, { useState } from 'react';
import {PageLayout} from '../components/layout/PageLayout/PageLayout';
import Card from '../components/common/elements/Card/Card';
import Table from '../components/common/elements/Table/Table';
import FormFilter from '../components/common/elements/FormFilter/FormFilter';
import Modal from '../components/common/elements/Modal/Modal';
import TextField from '@mui/material/TextField';
import { fetchData, postData } from '../services/api';
import SnackbarAlert from '../components/common/elements/SnackbarAlert/SnackbarAlert';

const System = () => {
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [filterValue, setFilterValue] = useState(''); 
    const [systemName, setSystemName] = useState('');
    const [systemDescription, setSystemDescription] = useState('');    
    const [systemNameError, setSystemNameError] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // or "error", "info", "warning"    

    const tableHeadData = ['Name', 'Description' ,'Code', 'Status', 'Action'];

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
    };

    const handleStatusChange = async ({ newStatus, rowIndex, row }) => {
        // Your API call and update logic here
        const originalData = [...tableData]; // Store original data
        const updatedData = [...tableData];
        updatedData[rowIndex].status = newStatus;
      
        const payload = { 
          SystemName: row.name, 
          ReferenceTableStatusID: newStatus === 'Active' ? 1 : 2 
        };
      
        try {
          await postData('systems/update', { ...payload, SystemID: row.id }); 
          setTableData(updatedData);
        } catch (error) {
          console.error("Failed to update:", error);
          setTableData(originalData);
        }
    };
      
    const handleButtonClick = (rowId) => {
        const row = tableData.find((data) => data.id === rowId);
        if (row) {
          setSelectedRowData(row);
          setSystemName(row.name); 
          setSystemDescription(row.desc); 
          setOpenModal(true);
        }
    };

    const performSearch = async () => {
        await fetchData('systems', { filter: filterValue, momo:123 })
        .then((result) => {
            if (result.code === 404) {
                setTableData([]); 
                setSnackbarMessage(result.message);
                setSnackbarOpen(true); 
            }else{
                const resultArray = Object.values(result.data).map((item) => ({
                    id: item.SystemID,
                    name: item.SystemName,
                    desc: item.SystemDescription,
                    code: item.SystemCode,
                    status: item.TableStatus,
                  }));
                setTableData(resultArray);
            }
          })
          .catch((error) => console.error('Error fetching data:', error));
    };

    const performNew = () => {
        setSystemName('');
        setSystemDescription('');
        setSystemNameError(false);
        setSelectedRowData(null); 
        setOpenModal(true);
    
    };
    
    const performClear = () => {
        setTableData([]); 
        setFilterValue(''); 
    }; 
    
    const handleSaveModal = async () => {
        setSystemNameError(!systemName);
        if (!systemName) return;
      
        try {
          const payload = { SystemName: systemName, SystemDescription: systemDescription };
          const endpoint = selectedRowData ? 'systems/update' : 'systems';
          const additionalData = selectedRowData ? { SystemID: selectedRowData.id } : {};
      
          const result = await postData(endpoint, { ...payload, ...additionalData });
      
          if (result.data.length > 0) {
            const { SystemID: id, SystemCode: code, SystemName: name, SystemDescription: desc, TableStatus: status } = result.data[0];
            const newEntry = { id, name, desc, code, status };
      
            setTableData(prevTableData => {
              if (selectedRowData) {
                return prevTableData.map(item => (item.id === selectedRowData.id ? newEntry : item));
              }
              return [...prevTableData, newEntry];
            });
      
            setOpenModal(false);

            const action = selectedRowData ? 'updated' : 'added';
            setSnackbarMessage(`Record successfully ${action}.`);
            setSnackbarSeverity('success');            
            setSnackbarOpen(true);
          }
        } catch (error) {
          console.error('Error saving:', error);
        }
    };
    
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <PageLayout
            sm={3}
            itemLeftPadding={2}
            alignItems="flex-start"
            breadcrumbs={[
                { label: "Home", href: "/" },
                { label: "Setup", href: "#" },
                { label: "System", href: "#" }
            ]}
        >
            <Card>
                <FormFilter 
                    handleSearch={performSearch}
                    handleNew={performNew} 
                    handleClear={performClear} 
                    filterValue={filterValue} 
                    setFilterValue={setFilterValue}                  
                />
            </Card>
            <div style={{ marginBottom: '20px' }}></div>
            <Table tableHeadData={tableHeadData} tableData={tableData} handleButtonClick={handleButtonClick} onStatusChange={handleStatusChange} />
            <Modal 
                width={400} 
                open={openModal} 
                handleSave={handleSaveModal} 
                handleClose={handleCloseModal} 
                modalTitle="System" 
            >
                <TextField
                    fullWidth
                    label="System Name"
                    type="search"
                    placeholder="Enter name here"
                    sx={{ mb: 2 }}
                    required
                    error={systemNameError}
                    helperText={systemNameError ? 'System Name is required' : ''}
                    value={systemName}
                    onChange={e => setSystemName(e.target.value)}                    
                />                           
                <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={4}
                    placeholder="Enter description here"
                    value={systemDescription}
                    onChange={e => setSystemDescription(e.target.value)}
                />                                
            </Modal>  
            <SnackbarAlert 
              open={snackbarOpen} 
              handleClose={handleSnackbarClose} 
              message={snackbarMessage} 
              severity={snackbarSeverity}
            />                      
        </PageLayout>
    );
};

export default System;